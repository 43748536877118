@import 'TVEpisode.css';
.seriesSection.placeholder .episode.tv > .header {
  height: 62px;
}
.seriesSection.placeholder .episode.tv > .header > .dateAndTitle.placeholder .date {
  background-color: var(--placeholder-text);
  height: 20px;
  width: 300px;
}
.seriesSection.placeholder .episode.tv > .header > .dateAndTitle.placeholder .title {
  background-color: var(--placeholder-text);
  height: 20px;
  width: 200px;
}
