@charset "UTF-8";
.vodPurchase {
  width: 500px;
}
.vodPurchase > .content .titles {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  font: 36px var(--bold-font);
  padding: 30px;
  text-align: left;
}
.vodPurchase > .content .titles > .subtitle {
  font: 24px var(--regular-font);
}
.vodPurchase > .content .invalidContent {
  font: 20px var(--semibold-font);
  justify-content: center;
  padding: 50px;
}
.vodPurchase > .content .validation {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow-y: auto;
  padding: 30px 30px 0;
}
.vodPurchase > .content .validation > .summary {
  align-items: center;
  display: flex;
  flex-direction: row;
  font: 24px var(--regular-font);
}
.vodPurchase > .content .validation > .summary > .price {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.vodPurchase > .content .validation > .summary > .price.discount {
  background-color: var(--purchase-discount-background);
  border-radius: var(--border-radius-tiny-u);
  color: var(--purchase-discount-foreground);
  font: 20px var(--semibold-font);
  padding: 2px 10px;
}
.vodPurchase > .content .validation > .summary > .price.struck {
  margin-left: 10px;
  text-decoration: line-through;
}
.vodPurchase > .content .validation > .details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font: 16px var(--regular-font);
  margin-top: 5px;
  opacity: 0.7;
  text-align: left;
}
.vodPurchase > .content .validation > .details .pictoInfo {
  border-radius: var(--border-radius-tiny-u);
  font-size: 12px;
  padding: 2px 6px;
}
.vodPurchase > .content .validation > .details > div {
  align-items: center;
  display: flex;
}
.vodPurchase > .content .validation > .details > div:not(:last-child)::after {
  border-radius: 50%;
  content: "";
  height: 5px;
  margin: 0 5px;
  opacity: 0.7;
  width: 5px;
}
.vodPurchase > .content .validation > .details > div.languages > .pictoInfo {
  border-style: solid;
  border-width: 1px;
  font: 14px var(--semibold-font);
  height: 24px;
}
.vodPurchase > .content .validation > .details > div.languages > .pictoInfo:not(:last-child) {
  margin-right: 5px;
}
.vodPurchase > .content .validation > .details > div.languages > .pictoInfo.impaired {
  padding-inline: 16px;
}
.vodPurchase > .content .validation > .details > div.languages > .pictoInfo > svg {
  width: 20px;
}
.vodPurchase > .content .validation > .details > div.parentalGuidance.pictoInfo {
  font: 15px var(--semibold-font);
}
.vodPurchase > .content .validation > .validity {
  flex-direction: column;
  font: 16px var(--regular-font);
  margin: 40px 0 10px;
}
.vodPurchase > .content .validation > .validity > .bullet {
  text-align: left;
}
.vodPurchase > .content .validation > .validity > .bullet::before {
  content: "•";
  margin-right: 10px;
}
.vodPurchase > .content .validation > .codeAndButton {
  align-items: center;
  margin: 20px 0 5px;
}
.vodPurchase > .content .validation > .codeAndButton > input {
  background-color: transparent;
  border: 2px solid var(--input-border);
  border-radius: var(--border-radius-huge-u);
  flex: 1;
  font: 14px var(--regular-font);
  height: 46px;
  margin-right: 20px;
  padding: 8px 12px;
  user-select: text;
}
.vodPurchase > .content .validation > .codeAndButton > .pictoElement > svg {
  fill: var(--success);
}
.vodPurchase > .content .validation > .appliedPromocode > .label {
  font: 14px var(--light-font);
}
.vodPurchase > .content .validation > .appliedPromocode > .promocode {
  font: 14px var(--regular-font);
  margin-left: 3px;
}
.vodPurchase > .content .validation > .promocodeError {
  font: 14px var(--regular-font);
  height: 60px;
  margin-bottom: 20px;
  opacity: 0;
  text-align: left;
}
.vodPurchase > .content .validation > .promocodeError.visible {
  opacity: 1;
}
.vodPurchase > .content .validation > .information {
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  margin: 8px 0;
}
.vodPurchase > .content .validation > .information > .pictoElement {
  width: 20px;
}
.vodPurchase > .content .validation > .information > .pictoElement > svg {
  fill: var(--accent);
  width: 16px;
}
.vodPurchase > .content .paid {
  flex-direction: column;
  margin: 30px;
  text-align: left;
}
.vodPurchase > .content .paid > .title {
  font: 24px var(--semibold-font);
}
.vodPurchase > .content .paid > .message {
  font: 18px var(--regular-font);
  margin: 30px 0;
}
.vodPurchase > .content .paid > .buttons {
  justify-content: space-between;
}
.vodPurchase.dark > .content {
  color: var(--overlay-foreground-1);
}
.vodPurchase.dark > .content .titles {
  border-bottom-color: var(--separator-dark);
}
.vodPurchase.dark > .content .validation > .summary > .price.struck {
  text-decoration-color: var(--overlay-foreground-1);
}
.vodPurchase.dark > .content .validation > .details > div:not(:last-child)::after {
  background-color: var(--overlay-foreground-1);
}
.vodPurchase.dark > .content .validation > .details > div.languages > .pictoInfo {
  border-color: var(--overlay-foreground-1);
}
.vodPurchase.dark > .content .validation > .details > div.languages > .pictoInfo > svg {
  fill: var(--overlay-foreground-1);
}
.vodPurchase.dark > .content .validation > .details > div.parentalGuidance.pictoInfo {
  background-color: var(--overlay-foreground-1);
  color: var(--overlay-background);
}
.vodPurchase.dark > .content .validation > .codeAndButton > input {
  background-color: var(--overlay-background);
  border-color: var(--placeholder-input);
  color: var(--overlay-foreground-1);
}
.vodPurchase.dark > .content .validation > .codeAndButton > input::placeholder {
  color: var(--placeholder-input);
}
.vodPurchase.dark > .content .validation > .promocodeError {
  color: var(--error);
}
.vodPurchase.light > .content {
  color: var(--overlay-background);
}
.vodPurchase.light > .content .titles {
  border-bottom-color: var(--separator-light);
}
.vodPurchase.light > .content .validation > .summary > .price.struck {
  text-decoration-color: var(--overlay-background);
}
.vodPurchase.light > .content .validation > .details > div:not(:last-child)::after {
  background-color: var(--overlay-background);
}
.vodPurchase.light > .content .validation > .details > div.languages > .pictoInfo {
  border-color: var(--overlay-background);
}
.vodPurchase.light > .content .validation > .details > div.parentalGuidance.pictoInfo {
  background-color: var(--overlay-background);
  color: var(--overlay-foreground-1);
}
.vodPurchase.light > .content .validation > .codeAndButton > input {
  background-color: var(--overlay-foreground-1);
  border-color: var(--placeholder-input);
  color: var(--overlay-background);
}
.vodPurchase.light > .content .validation > .codeAndButton > input::placeholder {
  color: var(--placeholder-input);
}
.vodPurchase.light > .content .validation > .promocodeError {
  color: var(--error);
}
