.channelZapper {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  overflow: visible;
}
.channelZapper > .channelListOpener {
  background-color: var(--accent);
  cursor: pointer;
  height: var(--channel-list-item-height-u);
  margin-bottom: 50px;
  width: 10px;
}
.channelZapper > .channelListOpener.disabled {
  cursor: default;
  opacity: 0;
}
.channelZapper > .channelList {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.channelZapper > .channelList > .pictoElement {
  height: 50px;
  width: 100%;
}
.channelZapper > .channelList > .pictoElement.disabled {
  opacity: 0;
}
.channelZapper > .channelList > .channelContainer {
  height: var(--channel-list-item-height-u);
  width: var(--channel-list-item-width-u);
}
.channelZapper > .channelList > .channelContainer > .channelSlider {
  align-items: center;
  flex-direction: column-reverse;
  overflow: visible;
  transition: transform var(--short-duration) ease-in-out;
  width: 100%;
}
.channelZapper.open {
  height: 100%;
}
.channelZapper.open > .channelList > .channelContainer {
  background-color: var(--player-channel-zapper-background);
  height: 100%;
}
