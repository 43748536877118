@keyframes modalSlideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalSlideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes iconSlideDown {
  0% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes iconSlideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-300%);
  }
}
.modalOverlayDiv {
  background-color: var(--dimmer);
  height: 100%;
  inset: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.modalContentDiv {
  --icon-size-u: 100px;
  --balloon-height-u: 88px;
  --balloon-width-u: 86px;
  cursor: pointer;
  inset: 0;
  padding: 20px;
  position: fixed;
  text-align: center;
  z-index: 10000;
}
.modalContentDiv.hasIcon {
  padding: 60px 10px 20px;
}
.modalContentDiv::after {
  content: "";
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  vertical-align: middle;
}
.modalContentDiv > .modalDialogDiv {
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-height: 100%;
  outline: 0;
  /*
   * Visible because of modal's top icon
   */
  overflow: visible;
  position: relative;
  vertical-align: middle;
}
.modalContentDiv > .modalDialogDiv > .content {
  border-radius: var(--border-radius-huge-u);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  will-change: transform;
}
.modalContentDiv > .modalDialogDiv > .content.slideDown {
  animation: modalSlideDown var(--short-duration) ease-out;
}
.modalContentDiv > .modalDialogDiv > .content.slideUp {
  animation: modalSlideUp var(--short-duration) ease-out;
}
.modalContentDiv > .modalDialogDiv > .content > .modalHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  z-index: 10;
}
.modalContentDiv > .modalDialogDiv > .content > .modalHeader.shadow {
  box-shadow: 0 0 2px 1px var(--shadow);
}
.modalContentDiv > .modalDialogDiv > .content > .modalHeader > .title {
  font: 18px var(--semibold-font);
  margin: 0 auto 0 10px;
}
.modalContentDiv > .modalDialogDiv > .content > .modalHeader > .title.clickable {
  cursor: pointer;
}
.modalContentDiv > .modalDialogDiv > .content > .modalHeader > .pictoElement {
  margin-right: 10px;
}
.modalContentDiv > .modalDialogDiv > .content > .modalHeader > .pictoElement > svg {
  width: 20px;
}
.modalContentDiv > .modalDialogDiv > .content > .header {
  align-items: center;
  border: none;
  display: flex;
  flex-direction: column;
  font: 20px var(--bold-font);
  margin-bottom: 0;
  padding: 30px var(--modal-inline-padding-u) 0 var(--modal-inline-padding-u);
}
.modalContentDiv > .modalDialogDiv > .content > .header.separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.modalContentDiv > .modalDialogDiv > .iconContainer {
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  justify-content: center;
  left: 50%;
  margin-left: calc(var(--icon-size-u) / -2);
  overflow: visible;
  position: absolute;
  top: calc(var(--icon-size-u) / -2);
  width: var(--icon-size-u);
  z-index: 15;
}
.modalContentDiv > .modalDialogDiv > .iconContainer.slideDown {
  animation: iconSlideDown var(--short-duration) ease-out;
}
.modalContentDiv > .modalDialogDiv > .iconContainer.slideUp {
  animation: iconSlideUp var(--short-duration) ease-out;
}
.modalContentDiv > .modalDialogDiv > .iconContainer > svg {
  fill: transparent;
  height: var(--balloon-height-u);
  left: 50%;
  margin-left: calc(var(--balloon-width-u) / -2);
  position: absolute;
  stroke-width: 2px;
  top: calc(var(--icon-size-u) - var(--balloon-height-u));
  width: var(--balloon-width-u);
}
.modalContentDiv > .modalDialogDiv > .iconContainer > .pictoElement > svg {
  width: 30px;
}
.modalContentDiv > .modalDialogDiv > .iconContainer > .pictoElement.recording {
  margin-top: -1px;
}
.modalContentDiv > .modalDialogDiv > .iconContainer > .pictoElement.recording > svg {
  width: 36px;
}
.modalContentDiv > .modalDialogDiv.dark > .content {
  background-color: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.dark > .content > .modalHeader {
  color: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.dark > .content > .modalHeader > .title {
  color: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.dark > .content > .modalHeader > .pictoElement > svg {
  fill: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.dark > .content > .header {
  border-bottom-color: var(--separator-dark);
  color: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.dark > .iconContainer {
  background-color: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.dark > .iconContainer > svg {
  stroke: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.dark > .iconContainer > .pictoElement > svg {
  fill: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.dark > .iconContainer > .pictoElement.recording > svg {
  fill: var(--recording);
}
.modalContentDiv > .modalDialogDiv.light > .content {
  background-color: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.light > .content > .modalHeader {
  color: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.light > .content > .modalHeader > .title {
  color: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.light > .content > .modalHeader > .pictoElement > svg {
  fill: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.light > .content > .header {
  border-bottom-color: var(--separator-light);
  color: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.light > .iconContainer {
  background-color: var(--overlay-foreground-1);
}
.modalContentDiv > .modalDialogDiv.light > .iconContainer > svg {
  stroke: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.light > .iconContainer > .pictoElement > svg {
  fill: var(--overlay-background);
}
.modalContentDiv > .modalDialogDiv.light > .iconContainer > .pictoElement.recording > svg {
  fill: var(--recording);
}
