.keyboardShortcuts > .content {
  color: var(--overlay-foreground-1);
  font: 16px var(--regular-font);
}
.keyboardShortcuts > .content > .keyboardShortcutsContent {
  flex: 1;
  overflow-y: auto;
  padding: 0 50px;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > .sectionTitle {
  font: 18px var(--semibold-font);
  margin: 20px 0 10px 100px;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul {
  display: grid;
  flex-direction: column;
  grid-template-columns: [shortcut-start] 110px [shortcut-end action-start] auto [action-end];
  list-style: none;
  margin: 0;
  overflow: visible;
  padding: 0 0 20px;
  grid-row-gap: 6px;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li {
  display: contents;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li:not(:last-child) {
  margin-bottom: 6px;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li > .shortcut {
  grid-column: shortcut-start/shortcut-end;
  margin-left: auto;
  overflow: visible;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li > .shortcut > span {
  background-color: var(--keyboard-shortcut-background);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 var(--shadow);
  color: var(--keyboard-shortcut-foreground);
  font-family: var(--monospace);
  justify-content: center;
  min-width: 26px;
  overflow: visible;
  padding: 5px 10px;
  position: relative;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li > .shortcut > span:not(:last-child) {
  margin-right: 15px;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li > .shortcut > span:not(:last-child)::after {
  content: "+";
  position: absolute;
  right: -12px;
}
.keyboardShortcuts > .content > .keyboardShortcutsContent > .column > ul > li > .action {
  align-items: center;
  grid-column: action-start/action-end;
  margin-left: 10px;
}
