.buttonFXBicolor > .button {
  /* stylelint-disable no-duplicate-selectors */
}
.buttonFXBicolor > .button > .content {
  flex-direction: row;
}
.buttonFXBicolor > .button > .content > .part {
  align-items: center;
  height: 100%;
}
.buttonFXBicolor > .button > .content > .part.left {
  flex-grow: 1;
  justify-content: center;
}
.buttonFXBicolor > .button > .content > .part.right {
  justify-content: center;
  padding: 0 10px;
  width: 75px;
}
.buttonFXBicolor > .button.dark > .content > .part.left {
  background-color: var(--button-transaction-dark-left-background);
  color: var(--button-transaction-dark-left-foreground);
}
.buttonFXBicolor > .button.dark > .content > .part.right {
  background-color: var(--button-transaction-dark-right-background);
  color: var(--button-transaction-dark-right-foreground);
}
.buttonFXBicolor > .button.dark > .content.hovered > .left {
  background-color: transparent !important;
}
.buttonFXBicolor > .button.dark > .content.hovered > .right {
  background-color: var(--button-transaction-dark-right-background-hover) !important;
}
.buttonFXBicolor > .button.light > .content > .part.left {
  background-color: var(--button-transaction-light-left-background);
  color: var(--button-transaction-light-left-foreground);
}
.buttonFXBicolor > .button.light > .content > .part.right {
  background-color: var(--button-transaction-light-right-background);
  color: var(--button-transaction-light-right-foreground);
}
.buttonFXBicolor > .button.light > .content.hovered > .left {
  background-color: transparent !important;
}
.buttonFXBicolor > .button.light > .content.hovered > .right {
  background-color: var(--button-transaction-dark-right-background-hover) !important;
}
.buttonFXBicolor > .button > .content.hovered > .part.left, .buttonFXBicolor > .button > .content.hovered > .part.right {
  color: var(--button-foreground-hover);
}
