.npvrConfirmation {
  width: 450px;
}
.npvrConfirmation > .content {
  color: var(--overlay-foreground-1);
  position: relative;
}
.npvrConfirmation > .content > .pictoElement {
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 15;
}
.npvrConfirmation > .content > .pictoElement > svg {
  fill: var(--overlay-foreground-1);
  left: 10px;
  top: 10px;
  width: 20px;
}
.npvrConfirmation > .content > .header > .episodeTitle {
  font: 18px var(--regular-font);
}
.npvrConfirmation > .content > .confirmationBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px var(--modal-inline-padding-u);
}
.npvrConfirmation > .content > .confirmationBody > div:first-child {
  margin-bottom: 20px;
}
.npvrConfirmation > .content > .confirmationBody > .confirmationOptions {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}
.npvrConfirmation > .content > .confirmationBody > .confirmationOptions > .confirmationOption {
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}
.npvrConfirmation > .content > .confirmationBody > .confirmationOptions > .confirmationOption > .confirmationLabel {
  font: 18px var(--semibold-font);
  margin-bottom: 10px;
}
.npvrConfirmation > .content > .confirmationBody > .confirmationOptions > .confirmationOption > input {
  text-align: center;
  width: 50px;
}
