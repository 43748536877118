.confirmation {
  max-width: 500px;
}
.confirmation > .content > .question {
  color: var(--overlay-foreground-1);
  font: 16px var(--regular-font);
  padding: 20px var(--modal-inline-padding-u);
}
.confirmation > .content > .buttons {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 var(--modal-inline-padding-u) 30px var(--modal-inline-padding-u);
}
.confirmation > .content > .buttons > div:last-child {
  margin-bottom: 20px;
}
