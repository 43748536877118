.section.channelSection {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  pointer-events: auto;
}
.section.channelSection > .header {
  align-items: center;
  color: var(--text-foreground-1);
  display: flex;
  font: 18px var(--bold-font);
  margin: 0 0 8px 13px;
  user-select: none;
}
.section.channelSection .itemGrid {
  display: flex;
  flex: 1 1;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.section.channelSection .itemGrid::after {
  content: "";
  flex: auto;
}
