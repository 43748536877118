.playerView > .debugOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: [col1-start] 1fr [col1-end col2-start] 1fr [col2-end col3-start] 1fr [col3-end];
  grid-template-rows: 1fr min-content auto;
  flex-direction: column;
  left: 80px;
  min-width: 200px;
  padding: 10px;
  position: absolute;
  top: 140px;
}
.playerView > .debugOverlay > .column1,
.playerView > .debugOverlay > .column2,
.playerView > .debugOverlay > .column3 {
  display: flex;
  flex-direction: column;
}
.playerView > .debugOverlay > .wholeRow {
  flex-direction: column;
  grid-column: col1-start/col3-end;
  margin-bottom: 10px;
  max-width: 750px;
}
.playerView > .debugOverlay > .column1 {
  grid-area: col1;
}
.playerView > .debugOverlay > .column2 {
  grid-area: col2;
}
.playerView > .debugOverlay > .column3 {
  grid-area: col3;
}
.playerView > .debugOverlay .pictoElement {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.playerView > .debugOverlay .pictoElement > svg {
  color: var(--accent);
}
.playerView > .debugOverlay .title {
  color: var(--accent);
  font: 12px var(--semibold-font);
}
.playerView > .debugOverlay .subsection {
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 5px;
}
.playerView > .debugOverlay .data {
  align-items: baseline;
  margin-left: 10px;
}
.playerView > .debugOverlay .data > .label {
  font: 10px var(--light-font);
}
.playerView > .debugOverlay .data > .label.button {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 0 3px;
}
.playerView > .debugOverlay .data > .label.button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.playerView > .debugOverlay .data > .label.button:active {
  background-color: #fff;
  color: #000;
}
.playerView > .debugOverlay .data > .value {
  font: 10px var(--regular-font);
  margin-left: 5px;
}
.playerView > .debugOverlay .data + .title {
  margin-top: 10px;
}
