.sectionItem {
  display: flex;
  flex-direction: column;
  overflow: visible;
  pointer-events: auto;
  z-index: 200;
}
.sectionItem * {
  transition: var(--opacity-short);
}
.sectionItem > .selectionBorder {
  align-items: center;
  background-color: transparent;
  border-radius: var(--tile-selection-border-radius-u);
  display: flex;
  justify-content: center;
  position: relative;
}
.sectionItem > .selectionBorder > .channelName {
  max-width: var(--tile-channel-image-width-u);
  pointer-events: none;
  position: absolute;
  width: fit-content;
  z-index: 1000;
}
.sectionItem .tileContainer {
  background: no-repeat center center;
  background-size: cover;
  border-radius: var(--tile-border-radius-u);
  cursor: pointer;
  display: flex;
  overflow: visible;
  position: relative;
  transform: translate3d(0, 0, 0);
  transform-origin: center center;
  transition: transform var(--short-duration) ease-out;
  will-change: transform;
  z-index: 300;
}
.sectionItem .tileContainer > .backgroundImage {
  animation-duration: var(--medium-duration);
  animation-fill-mode: both;
  border-radius: var(--tile-border-radius-u);
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
}
.sectionItem .tileContainer > .backgroundImage.visible {
  animation-name: fadeIn;
  animation-timing-function: ease-out;
  z-index: 302;
}
.sectionItem .tileContainer > .backgroundImage.hidden {
  animation-name: fadeOut;
  animation-timing-function: ease-in;
  z-index: 301;
}
.sectionItem .tileContainer.focused {
  transform: scale3d(1.22, 1.22, 1);
}
.sectionItem .tileContainer.focused > .decoration {
  opacity: 0;
}
.sectionItem .tileContainer.focused > .overlay {
  opacity: 1;
}
.sectionItem .tileContainer.focused ~ .textContainer {
  opacity: 0;
}
.sectionItem .tileContainer > .channelName {
  align-items: center;
  color: var(--text-foreground-1);
  display: flex;
  inset: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
}
.sectionItem:hover {
  z-index: 340;
}
.sectionItem > .textContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: var(--tile-text-height-u);
  margin-top: 2px;
  opacity: 1;
  z-index: 250;
}
.sectionItem > .textContainer .text {
  color: var(--text-foreground-1);
  flex: 1 0;
  font: 16px var(--semibold-font);
  text-align: left;
}
.sectionItem > .textContainer .text.secondLineInfo {
  color: var(--text-foreground-2);
  font: 14px var(--regular-font);
}
.sectionItem > .textContainer.placeholder {
  cursor: default;
}
.sectionItem > .textContainer.placeholder > .text {
  flex: 0 1 auto;
  height: 17px;
  margin-bottom: 2px;
  width: 200px;
}
.sectionItem > .textContainer.placeholder > .text.secondLineInfo {
  width: 140px;
}

.section.gemtv .sectionItem {
  margin-right: var(--tile-gemtv-horizontal-space-u);
  width: var(--tile-gemtv-image-width-u);
}
.section.gemtv .sectionItem > .tileContainer {
  height: var(--tile-gemtv-image-height-u);
}
.section.gemtv.medium .sectionItem {
  width: var(--tile-landscape-image-width-u);
}
.section.gemtv.medium .sectionItem > .tileContainer {
  height: var(--tile-landscape-image-height-u);
}
.section.gemtv.big .sectionItem {
  width: var(--tile-landscape-big-image-width-u);
}
.section.gemtv.big .sectionItem > .tileContainer {
  height: var(--tile-landscape-big-image-height-u);
}
.section.portrait .sectionItem > .tileContainer, .section.deeplink .sectionItem > .tileContainer, .section.landscape .sectionItem > .tileContainer {
  background-size: 100% 100%;
}
.section.portrait .sectionItem {
  margin-right: var(--tile-portrait-horizontal-space-u);
  width: var(--tile-portrait-image-width-u);
}
.section.portrait .sectionItem > .tileContainer {
  height: var(--tile-portrait-image-height-u);
}
.section.deeplink .sectionItem {
  margin-right: var(--tile-deeplink-horizontal-space-u);
  width: var(--tile-deeplink-image-width-u);
}
.section.deeplink .sectionItem > .tileContainer {
  height: var(--tile-deeplink-image-height-u);
}
.section.deeplink5x1 .sectionItem {
  margin-right: var(--tile-deeplink-5x1-horizontal-space-u);
  width: var(--tile-deeplink-5x1-image-width-u);
}
.section.deeplink5x1 .sectionItem > .selectionBorder {
  height: calc(var(--tile-deeplink-5x1-image-height-u) + 2 * var(--selection-thickness-u));
  width: calc(var(--tile-deeplink-5x1-image-width-u) + 2 * var(--selection-thickness-u));
}
.section.deeplink5x1 .sectionItem > .selectionBorder:hover {
  background-color: var(--accent);
}
.section.deeplink5x1 .sectionItem .tileContainer {
  background-size: 100% 100%;
  height: var(--tile-deeplink-5x1-image-height-u);
  width: var(--tile-deeplink-5x1-image-width-u);
}
.section.deeplink3x1 .sectionItem {
  margin-right: var(--tile-deeplink-3x1-horizontal-space-u);
  width: var(--tile-deeplink-3x1-image-width-u);
}
.section.deeplink3x1 .sectionItem > .selectionBorder {
  height: calc(var(--tile-deeplink-3x1-image-height-u) + 2 * var(--selection-thickness-u));
  width: calc(var(--tile-deeplink-3x1-image-width-u) + 2 * var(--selection-thickness-u));
}
.section.deeplink3x1 .sectionItem > .selectionBorder:hover {
  background-color: var(--accent);
}
.section.deeplink3x1 .sectionItem .tileContainer {
  background-size: 100% 100%;
  height: var(--tile-deeplink-3x1-image-height-u);
  width: var(--tile-deeplink-3x1-image-width-u);
}
.section.landscape .sectionItem {
  margin-right: var(--tile-landscape-horizontal-space-u);
  width: var(--tile-landscape-image-width-u);
}
.section.landscape .sectionItem > .tileContainer {
  height: var(--tile-landscape-image-height-u);
}
.section.landscape .sectionItem > .selectionBorder {
  height: calc(var(--tile-landscape-image-height-u) + 2 * var(--selection-thickness-u));
  width: calc(var(--tile-landscape-image-width-u) + 2 * var(--selection-thickness-u));
}
.section.landscape .sectionItem > .selectionBorder:hover {
  background-color: var(--accent);
}
.section.landscape .sectionItem > .selectionBorder > .tileContainer {
  height: var(--tile-landscape-image-height-u);
  width: var(--tile-landscape-image-width-u);
}
.section.landscape.big .sectionItem {
  margin-right: var(--tile-landscape-big-horizontal-space-u);
  width: var(--tile-landscape-big-image-width-u);
}
.section.landscape.big .sectionItem > .selectionBorder {
  height: calc(var(--tile-landscape-big-image-height-u) + 2 * var(--selection-thickness-u));
  width: calc(var(--tile-landscape-big-image-width-u) + 2 * var(--selection-thickness-u));
}
.section.landscape.big .sectionItem > .selectionBorder:hover {
  background-color: var(--accent);
}
.section.landscape.big .sectionItem .tileContainer {
  height: var(--tile-landscape-big-image-height-u);
  width: var(--tile-landscape-big-image-width-u);
}
.section.landscape.vod .sectionItem {
  margin-right: var(--tile-landscape-vod-horizontal-space-u);
  width: var(--tile-landscape-vod-image-width-u);
}
.section.landscape.vod .sectionItem > .tileContainer {
  height: var(--tile-landscape-vod-image-height-u);
}
.section.landscape.vod .sectionItem > .tileContainer > .overlay > .pricing {
  align-self: center;
  column-gap: 20px;
  flex-direction: row;
  justify-content: center;
  min-width: 210px;
}
.section.landscape.vod .sectionItem > .tileContainer > .overlay > .pricing.reversed {
  flex-direction: row-reverse;
}
.section.landscape.vod .sectionItem > .tileContainer > .overlay > .pricing > .availabilityDate {
  margin-bottom: 0;
}
.section.landscape.vod .sectionItem > .tileContainer > .overlay .buttonFX {
  align-self: center;
}
.section.landscape.vod .sectionItem > .tileContainer > .overlay .buttonFXBicolor {
  margin: 0;
}
.section.channelgroup {
  --selection-width: calc(var(--tile-channel-group-image-width-u) + 2 * var(--selection-thickness-u));
  --selection-height: calc(var(--tile-channel-group-image-height-u) + 2 * var(--selection-thickness-u));
}
.section.channelgroup .sectionItem {
  width: calc(var(--tile-channel-group-image-width-u) + var(--tile-channel-group-horizontal-space-u));
  z-index: 200;
}
.section.channelgroup .sectionItem .tileContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  height: var(--tile-channel-group-image-height-u);
  width: var(--tile-channel-group-image-width-u);
}
.section.channelgroup:not(.cover) .sectionItem .selectionBorder .tileContainer {
  background-color: var(--tile-background);
}
.section.channelgroup .selectionBorder {
  height: calc(var(--tile-channel-group-image-height-u) + 2 * var(--selection-thickness-u));
  width: calc(var(--tile-channel-group-image-width-u) + 2 * var(--selection-thickness-u));
}
.section.channelgroup .selectionBorder:hover {
  background-color: var(--accent);
}
.section.channelgroup .selectionBorder:hover > .tileContainer {
  background-color: var(--tile-background-hover);
}
.section.channelgroup.big .sectionItem {
  margin-right: var(--tile-channel-group-big-horizontal-space-u);
  width: var(--tile-channel-group-big-image-width-u);
}
.section.channelgroup.big .sectionItem .selectionBorder {
  height: calc(var(--tile-channel-group-big-image-height-u) + 2 * var(--selection-thickness-u));
  width: calc(var(--tile-channel-group-big-image-width-u) + 2 * var(--selection-thickness-u));
}
.section.channelgroup.big .sectionItem .selectionBorder > .tileContainer {
  height: var(--tile-channel-group-big-image-height-u);
  width: var(--tile-channel-group-big-image-width-u);
}
.section.channel {
  --height-u: calc(var(--tile-channel-image-height-u) + 2 * var(--selection-thickness-u));
  --width-u: calc(var(--tile-channel-image-width-u) + 2 * var(--selection-thickness-u));
  --margin-u: calc(18px - 2 * var(--selection-thickness-u));
}
.section.channel .sectionItem {
  height: var(--height-u);
  width: var(--width-u);
}
.section.channel .sectionItem .tileContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: var(--tile-channel-image-height-u);
  width: var(--tile-channel-image-width-u);
}
.section.channel .selectionBorder {
  height: var(--height-u);
  width: var(--width-u);
}
.section.channel .selectionBorder:hover {
  background-color: var(--accent);
}
.section.channel .selectionBorder:hover > .tileContainer {
  background-color: var(--tile-background-hover);
}
.section.cover .sectionItem > .selectionBorder > .tileContainer {
  background-size: cover;
}
.section .sectionItem .tileContainer {
  background-color: var(--placeholder-tile);
}
.section .sectionItem .selectionBorder .tileContainer {
  background-color: var(--placeholder-tile);
}
.section .sectionItem > .textContainer.placeholder > .text {
  background-color: var(--placeholder-tile);
}
.section:not(.placeholder).channel .sectionSlider, .section:not(.placeholder).channelgroup .sectionSlider, .section:not(.placeholder).landscape.big .sectionSlider, .section:not(.placeholder).deeplink3x1 .sectionSlider, .section:not(.placeholder).deeplink5x1 .sectionSlider {
  margin-left: calc(-1 * var(--selection-thickness-u));
}
