.externalContent {
  height: 100%;
  position: relative;
  width: 1200px;
}
.externalContent iframe {
  border: 0;
  height: 100%;
  width: 100%;
}
.externalContent .loader {
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
}
