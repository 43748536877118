@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.avenueView {
  --picto-huge-size-u: 150px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  width: 100%;
}
.avenueView > .avenueHeader {
  animation: fadeIn var(--medium-duration) ease-in forwards;
  background-repeat: no-repeat;
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.avenueView > .avenueHeader + .slider {
  margin-top: calc(110px + var(--header-height-u));
}
.avenueView > .slider {
  --carousel-margin: calc(40px + var(--header-height-u));
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 20px;
  overflow: visible;
}
.avenueView > .slider:first-child .section:first-child {
  margin-top: var(--carousel-margin);
}
.avenueView > .slider:first-child .sectionCarousel + .section {
  margin-top: var(--carousel-margin);
}
.avenueView > .slider > .noResult {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 50px;
}
.avenueView > .slider > .noResult > .pictoElement {
  height: auto;
}
.avenueView > .slider > .noResult > .pictoElement > svg {
  cursor: default;
  fill: var(--text-foreground-1);
  opacity: 0.8;
  width: var(--picto-huge-size-u);
}
.avenueView > .slider > .noResult > .noResultText {
  color: var(--text-foreground-1);
  font: 18px var(--regular-font);
  margin-top: 30px;
  width: auto;
}
.avenueView > .slider > .emptyMyVideos {
  align-items: center;
  color: var(--text-foreground-1);
  flex-direction: column;
  margin: 10% auto;
}
.avenueView > .slider > .emptyMyVideos > .title {
  font: 20px var(--bold-font);
}
.avenueView > .slider > .emptyMyVideos > .subtitle {
  font: 16px var(--regular-font);
  margin-top: 10px;
}
.avenueView > .slider > .emptyMyVideos > .iconAndText {
  align-items: center;
  margin-top: 40px;
}
.avenueView > .slider > .emptyMyVideos > .iconAndText > .pictoElement {
  height: auto;
  margin: 0 40px 0 0;
  width: auto;
}
.avenueView > .slider > .emptyMyVideos > .iconAndText > .pictoElement > svg {
  fill: var(--text-foreground-1);
  width: var(--picto-huge-size-u);
}
.avenueView > .slider > .emptyMyVideos > .iconAndText > .pictoElement > svg path:last-child {
  fill: var(--favorite);
}
.avenueView > .slider > .emptyMyVideos > .iconAndText > div {
  flex-direction: column;
}
.avenueView > .slider > .emptyMyVideos > .iconAndText > div > ul {
  display: flex;
  flex-direction: column;
  list-style: square inside none;
  margin: 10px 0 0;
  padding: 0;
}
.avenueView > .slider > .emptyMyVideos > .iconAndText > div > ul > li {
  display: list-item;
  font: 16px var(--regular-font);
  margin-bottom: 5px;
}
.avenueView > .slider > .emptyMyVideos > .image {
  background-position: top;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
}
.avenueView > .slider > .search {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 50px;
  padding: 50px;
  pointer-events: auto;
  width: auto;
}
.avenueView > .slider > .search > .searchBox {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  margin-bottom: 50px;
  overflow: visible;
  width: auto;
}
.avenueView > .slider > .search > .searchBox > .pictoElement {
  margin-right: 20px;
  transform-origin: 13px 20px;
  width: 30px;
}
.avenueView > .slider > .search > .searchBox > .pictoElement > svg {
  fill: var(--text-foreground-1);
  width: 100%;
}
.avenueView > .slider > .search > .searchBox > input {
  border: 0;
  border-bottom: 1px solid var(--search-avenue-bottom);
  border-radius: 0;
  color: var(--text-foreground-1);
  flex: 1;
  font: 30px var(--regular-font);
  height: auto;
  justify-content: center;
  padding: 20px 0;
  user-select: text;
}
.avenueView > .slider > .search > .searchBox > input::-moz-selection {
  background: var(--text-foreground-1);
  color: var(--background);
}
.avenueView > .slider > .search > .searchBox > input::selection {
  background: var(--text-foreground-1);
  color: var(--background);
}
.avenueView > .slider > .search > .searchBox > input::placeholder {
  color: var(--placeholder-input);
}
.avenueView > .slider > .search.loading > .searchBox > .pictoElement {
  animation: rotating var(--medium-duration) linear infinite;
}
.avenueView > .avenueImage {
  background: no-repeat left center;
  background-size: contain;
  height: var(--avenue-image-height-u);
  margin: var(--header-height-u) 0 -10px 40px;
  width: var(--avenue-image-width-u);
}
.avenueView > .backBar {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  margin: calc(33px + var(--header-height-u)) auto 20px 50px;
}
.avenueView > .backBar + .slider > .sectionCarousel + .section {
  margin-top: -12px;
}
.avenueView > .backBar + .avenueImage {
  margin-top: 0;
}

.modalContentDiv .avenueView.explore {
  margin-top: 20px;
}
.modalContentDiv .avenueView.explore > .slider .selectionBorder > .container {
  background-color: var(--tile-background-explorer);
}
.modalContentDiv .avenueView.explore > .slider .selectionBorder:hover > .container {
  background-color: var(--tile-background-explorer-hover);
}
.modalContentDiv .avenueView.explore .section.channel .tileContainer, .modalContentDiv .avenueView.explore .section.channelgroup .tileContainer {
  background-color: var(--tile-background);
}
