.itemSlide {
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding-left: 100px;
  pointer-events: none;
  position: absolute;
  transition: opacity var(--medium-duration) ease-in-out;
  z-index: 250;
}
.itemSlide.visible {
  opacity: 1;
  pointer-events: all;
  z-index: 255;
}
.itemSlide > .infoContainer {
  align-items: flex-start;
  color: var(--carousel-foreground);
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.itemSlide > .infoContainer .overTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: visible;
}
.itemSlide > .infoContainer .overTitle > .text,
.itemSlide > .infoContainer .overTitle > .separator {
  font: 16px var(--regular-font);
  margin-right: 12px;
}
.itemSlide > .infoContainer .overTitle > .text {
  opacity: 0.8;
  transition: var(--opacity-short);
}
.itemSlide > .infoContainer .overTitle > .text.clickable {
  cursor: pointer;
}
.itemSlide > .infoContainer .overTitle > .text.clickable:hover {
  opacity: 1;
}
.itemSlide > .infoContainer .overTitle > .liveRecording {
  align-items: center;
  margin-left: 10px;
}
.itemSlide > .infoContainer .overTitle > .liveRecording > .pictoElement > .background {
  left: 4px;
  top: 4px;
  width: 20px;
}
.itemSlide > .infoContainer .overTitle > .liveRecording > .pictoElement > svg {
  width: 12px;
}
.itemSlide > .infoContainer .overTitle > .liveRecording > .text {
  margin-left: 5px;
}
.itemSlide > .infoContainer .title {
  font: 38px var(--bold-font);
  line-height: 50px;
  margin-block: 5px 32px;
  max-width: 800px;
}
.itemSlide > .infoContainer > .iconBar {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  overflow: visible;
}
.itemSlide > .infoContainer > .iconBar > .pictoElement {
  margin: 0 8px;
  width: 44px;
}
.itemSlide > .infoContainer > .iconBar > .pictoElement > svg {
  width: 28px;
}
.itemSlide > .infoContainer > .iconBar > .pictoElement.play {
  margin-right: 16px;
  width: 64px;
}
.itemSlide > .infoContainer > .iconBar > .pictoElement.play > svg {
  width: 28px;
}
.itemSlide > .infoContainer > .iconBar > .spacedButton {
  margin-right: 20px;
}
