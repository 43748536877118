.itemIndex {
  --item-index-width-u: 50px;
  color: var(--carousel-foreground);
  display: flex;
  height: 19px;
  overflow: visible;
  position: relative;
  width: auto;
}
.itemIndex > .videoProgressContainer {
  height: 19px;
  position: absolute;
  width: calc(100% - 2px);
}
.itemIndex > .videoProgressContainer > .videoProgress {
  background: var(--player-progress-bar-buffered);
  display: none;
  height: 100%;
  position: absolute;
  width: 0;
}
.itemIndex.focused > .index > p {
  color: var(--accent);
  font: 14px var(--bold-font);
}
.itemIndex.focused .videoProgress {
  display: flex;
}
.itemIndex > .index {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: var(--item-index-width-u);
}
.itemIndex > .index > p {
  display: inline;
  font: 14px var(--light-font);
  margin: 0;
  text-align: center;
  user-select: none;
}
.itemIndex > .index > p:hover {
  font-family: var(--bold-font);
}
.itemIndex > .index > p::after {
  content: "00";
  display: block;
  font-family: var(--bold-font);
  height: 0;
  visibility: hidden;
}
.itemIndex:not(:last-child) > .index > p {
  border-right: 2px solid var(--carousel-foreground);
}
