.multipleChoices {
  align-items: center;
  cursor: pointer;
  font: 14px var(--semibold-font);
  position: relative;
}
.multipleChoices.disabled {
  cursor: default;
}
.multipleChoices.disabled > .disabledLayer {
  display: block;
}
.multipleChoices > .disabledLayer {
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.multipleChoices > .labels {
  --choice-count: 0;
  --offset-x: 0;
  --label-height: 0;
  --label-width: 0;
  display: grid;
  grid-template-columns: repeat(var(--choice-count), 1fr);
  width: 100%;
}
.multipleChoices > .labels::before {
  background-color: var(--multiple-choice-background-selected);
  border-radius: var(--border-radius-tiny-u);
  content: "";
  display: block;
  height: var(--label-height);
  position: absolute;
  transform: translateX(var(--offset-x));
  transition: transform var(--short-duration) ease-out;
  width: var(--label-width);
}
.multipleChoices > .labels > .label {
  align-items: center;
  border: 1px solid var(--multiple-choice-border);
  color: var(--multiple-choice-foreground);
  justify-content: center;
  padding: 10px 50px;
  transition: color var(--short-duration) ease-out;
  user-select: none;
  z-index: 1;
}
.multipleChoices > .labels > .label:first-child {
  border-radius: var(--border-radius-tiny-u) 0 0 var(--border-radius-tiny-u);
}
.multipleChoices > .labels > .label:last-child {
  border-radius: 0 var(--border-radius-tiny-u) var(--border-radius-tiny-u) 0;
}
.multipleChoices > .labels > .label.small {
  padding: 10px;
}
.multipleChoices > .labels > .label.medium {
  padding: 10px 30px;
}
.multipleChoices > .labels > .label.large {
  padding: 10px 50px;
}
.multipleChoices > .labels > .label:hover {
  background-color: var(--multiple-choice-background-hover);
  color: var(--multiple-choice-foreground-hover);
  border-color: var(--multiple-choice-border-hover);
}
.multipleChoices > .labels > .label.selected {
  color: var(--multiple-choice-foreground-selected);
  border-color: var(--multiple-choice-border-selected);
}
