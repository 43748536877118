@keyframes fade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  30% {
    background-color: #ffccf6;
  }
  45% {
    background-color: #ccdcff;
  }
  60% {
    background-color: #ccffe6;
  }
  75% {
    background-color: #ffefcc;
  }
  85% {
    background-color: #fff;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes numberFade {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.unicornized {
  align-items: center;
  animation: 6s ease-out fade;
  background-color: #fff;
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 0;
  position: absolute;
  z-index: 1000000;
}
.unicornized > .countDown {
  animation: 1s ease-out numberFade;
  color: #000;
  font: bold 400px Helvetica, sans-serif;
  opacity: 0;
  position: absolute;
}
.unicornized > .countDown.number0 {
  animation-delay: 1s;
}
.unicornized > .countDown.number1 {
  animation-delay: 2s;
}
.unicornized > .countDown.number2 {
  animation-delay: 3s;
}
.unicornized > .countDown.number3 {
  animation-delay: 4s;
  background-clip: text;
  background-color: transparent;
  background-image: linear-gradient(#f00 30%, #ffa500, #ff0, #008000, #00f, #ee82ee 80%);
  color: transparent;
  font-size: 240px;
}

.bg1,
.bg2,
.bg3,
.bg4 {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 10000;
}

.bg1 {
  background: #ffccf6;
}

.bg2 {
  background: #ccdcff;
}

.bg3 {
  background: #ccffe6;
}

.bg4 {
  background: #ffefcc;
}

.unicorn {
  overflow: visible;
  transform: scale(0.5);
}

.unicorn * {
  display: block;
  overflow: visible;
}

.face {
  height: 6rem;
  width: 8rem;
  background: #efeeef;
  border-radius: 4rem;
  transform: rotate(20deg);
}

.face * {
  position: absolute;
}

.cheek {
  width: 3rem;
  height: 3rem;
  background: radial-gradient(#eccbdf 5%, #efeeef 70%);
  top: 2rem;
  left: 4rem;
  border-radius: 50%;
  z-index: 1;
}

.snoot {
  height: 7rem;
  width: 14rem;
  background: #efeeef;
  left: -5rem;
  border-radius: 4rem;
  transform: rotate(-40deg);
}

.nose {
  height: 7rem;
  width: 3.5rem;
  background: #e4a3ca;
  border-radius: 50% 0 0 50%;
}

.nostril {
  height: 2rem;
  width: 1rem;
  background: #5a5056;
  top: 1.5rem;
  left: 1rem;
  border-radius: 50%;
}

.eye {
  width: 2.5rem;
  height: 1rem;
  background: #332a2f;
  left: 3rem;
  border-radius: 3rem 3rem 0 0;
}

.eye::after {
  content: "";
  height: 1rem;
  width: 1.5rem;
  background: #efeeef;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  border-radius: 3rem 3rem 0 0;
}

.eyelashes {
  height: 1rem;
  width: 0.5rem;
  background: #332a2f;
  top: -0.5rem;
  left: 1rem;
}

.eyelashes::before {
  content: "";
  height: 1rem;
  width: 0.5rem;
  background: #332a2f;
  position: absolute;
  top: 0.25rem;
  left: -1rem;
  transform: rotate(-40deg);
}

.eyelashes::after {
  content: "";
  height: 1rem;
  width: 0.5rem;
  background: #332a2f;
  position: absolute;
  top: 0.25rem;
  left: 1rem;
  transform: rotate(30deg);
}

.ear {
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-bottom: 4rem solid #efeeef;
  top: -5rem;
  left: 2.5rem;
  transform: rotate(-5deg);
  z-index: 1;
}

.ear::after {
  content: "";
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-bottom: 2rem solid #e4a3ca;
  position: absolute;
  top: 0.75rem;
  left: -0.75rem;
}

.horn {
  height: 7rem;
  width: 3rem;
  background: repeating-linear-gradient(-25deg, #ffe3aa, #ffe3aa 0.5rem, #bea97e 1rem, #bea97e 0.5rem);
  top: -7rem;
  left: -0.5rem;
  transform: rotate(-55deg);
  z-index: -1;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.mane.top {
  height: 2rem;
  width: 4rem;
  top: -1.5rem;
  left: 1.5rem;
  border-radius: 50%;
  box-shadow: 7px 1rem 0 6px #a891b6;
  transform: rotate(140deg);
}

.mane.top::before {
  content: "";
  height: 2rem;
  width: 3rem;
  position: absolute;
  top: 2.5rem;
  left: -3rem;
  border-radius: 50%;
  box-shadow: 5px 1rem 0 6px #ffead9;
  transform: rotate(260deg);
}

.mane.top::after {
  content: "";
  height: 2rem;
  width: 4rem;
  position: absolute;
  top: -0.5rem;
  left: -2rem;
  border-radius: 50%;
  box-shadow: 5px 1rem 0 6px #c6e8d7;
  transform: rotate(100deg);
}

.mane.mid {
  height: 2rem;
  width: 4rem;
  top: -1rem;
  left: 4.5rem;
  border-radius: 50%;
  box-shadow: 3px 1rem 0 8px #ffcbc1;
  transform: rotate(250deg);
  z-index: -1;
}

.mane.mid::before {
  content: "";
  height: 2rem;
  width: 4rem;
  position: absolute;
  left: -2rem;
  border-radius: 50%;
  box-shadow: 5px 1rem 0 6px #afc8ff;
  transform: rotate(40deg);
}

.mane.mid::after {
  content: "";
  height: 2rem;
  width: 4rem;
  position: absolute;
  top: 1rem;
  left: -1rem;
  border-radius: 50%;
  box-shadow: 0 1rem 0 0.5rem #ff9cee;
  transform: rotate(40deg);
  z-index: -1;
}

.neck {
  position: absolute;
  height: 5rem;
  width: 3rem;
  background: #efeeef;
  left: 5rem;
  top: 5rem;
  transform: skew(-20deg);
  z-index: -1;
}

.mane.end {
  height: 2rem;
  width: 4rem;
  top: 1rem;
  border-radius: 50%;
  box-shadow: 3px 1rem 0 8px #b6edb6;
  transform: rotate(270deg);
}

.mane.end::before {
  content: "";
  height: 3rem;
  width: 5rem;
  position: absolute;
  left: 1rem;
  border-radius: 50%;
  box-shadow: 5px 1rem 0 6px #caf;
}

.mane.end::after {
  content: "";
  height: 3rem;
  width: 5rem;
  position: absolute;
  top: 0.5rem;
  left: -0.5rem;
  border-radius: 50%;
  box-shadow: 5px 1rem 0 6px #ffbdbd;
  transform: rotate(30deg);
  z-index: -1;
}
