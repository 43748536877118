.episode {
  display: flex;
  flex-direction: column;
  overflow: visible;
  pointer-events: auto;
  position: relative;
  z-index: 200;
}
.episode::after {
  border-left: 4px solid var(--accent);
  bottom: 0;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: var(--opacity-short), transform var(--short-duration) ease-out;
  z-index: 300;
}
.episode:hover > .header {
  background-color: var(--tile-background-past-hover);
}
.episode:hover > .header.future {
  background-color: var(--tile-background-future-hover);
}
.episode.expanded::after {
  opacity: 1;
  transform: scaleX(1);
}
.episode.expanded > .header > .actions > .arrow > svg {
  opacity: 0.7;
  transform: scale(0.9) rotate(-180deg);
}
.episode.expanded > .header > .actions > .arrow > svg:hover {
  opacity: 1;
  transform: scale(1) rotate(-180deg);
}
.episode.expanded > .content {
  max-height: 100vh;
  padding: 10px 20px;
}
.episode > .header {
  align-items: center;
  background-color: var(--tile-background-past);
  border-radius: var(--border-radius-medium-u);
  color: var(--text-foreground-1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
}
.episode > .header.future {
  background-color: var(--tile-background-future);
}
.episode > .header > .actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  overflow: visible;
}
.episode > .header > .actions > .watchingStatus {
  height: 34px;
  margin: -3px 0 -3px 20px;
  width: 34px;
}
.episode > .header > .actions > .watchingStatus > svg {
  height: 38px;
  margin: -2px 0 0 -2px;
  width: 38px;
}
.episode > .header > .actions > .watchingStatus > svg > circle {
  stroke-width: 8px;
}
.episode > .header > .actions > .watchingStatus > .pictoElement > svg {
  margin-right: -1px;
  width: 12px;
}
.episode > .header > .actions > .arrow {
  margin-left: 20px;
}
.episode > .header > .actions > .arrow > svg {
  fill: var(--text-foreground-1);
  opacity: 0.7;
  transition-timing-function: ease-out;
  width: 16px;
}
.episode > .header > .actions > .arrow > svg:hover {
  opacity: 1;
  transform: scale(1);
}
.episode > .content {
  display: flex;
  flex-direction: row;
  max-height: 0;
  padding: 0 20px;
  transition: padding var(--short-duration) ease-in-out, max-height var(--short-duration) ease-in-out;
}
.episode > .content > .cover {
  background: no-repeat center center;
  background-size: cover;
}
.episode > .content > .details {
  align-items: flex-start;
  color: var(--text-foreground-1);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 10px;
  z-index: 250;
}
.episode > .content > .details > .date {
  margin: 10px 0;
}
.episode > .content > .details > .programInfo {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.episode > .content > .details > .programInfo .pictoInfo {
  border-radius: var(--border-radius-tiny-u);
  font-size: 14px;
  padding: 2px 6px;
}
.episode > .content > .details > .programInfo > div {
  align-items: center;
  display: flex;
}
.episode > .content > .details > .programInfo > div:not(:last-child):not(.statusPicto)::after {
  background-color: var(--text-foreground-1);
  border-radius: 50%;
  content: "";
  height: 5px;
  margin: 0 5px;
  opacity: 0.7;
  width: 5px;
}
.episode > .content > .details > .programInfo > div.languages > .pictoInfo {
  border: 1px solid var(--text-foreground-1);
}
.episode > .content > .details > .programInfo > div.languages > .pictoInfo:not(:last-child) {
  margin-right: 5px;
}
.episode > .content > .details > .programInfo > div.parentalGuidance.pictoInfo {
  background-color: var(--text-foreground-1);
  color: var(--background);
}
.episode > .content > .details > .synopsis {
  font: 14px var(--regular-font);
  text-align: left;
  margin: 0 0 10px;
}
