.statusPicto {
  align-items: center;
  border-radius: var(--border-radius-tiny-u);
  color: var(--picto-foreground);
  font: 14px var(--semibold-font);
  justify-content: center;
  padding: 3px 8px;
  user-select: none;
}
.statusPicto.clickable {
  cursor: pointer;
}
.statusPicto.live {
  background-color: var(--live);
}
.statusPicto.future {
  background-color: var(--future);
}
.statusPicto.catchup, .statusPicto.preview {
  background-color: var(--catchup);
}
.statusPicto.recording {
  background-color: var(--recording);
}
.statusPicto.past {
  background-color: var(--past);
}
