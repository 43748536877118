.cardAvenueView {
  background-color: var(--card-background);
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.cardAvenueView > .sectionButtonBar {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-top: 40px;
}
.cardAvenueView > .sectionButtonBar > .tabButton {
  height: 34px;
  margin-right: 20px;
  width: 182px;
}
.cardAvenueView > .sectionOrderBar {
  align-items: center;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  padding-right: 40px;
}
