.pricing {
  background-color: var(--transaction-status-purchased-background-tile);
  color: var(--transaction-status-purchased-foreground);
  display: flex;
  flex-direction: column;
  font: 14px var(--semibold-font);
  padding-inline: 10px;
}
.pricing.spaced {
  row-gap: 10px;
}
.pricing.reversed {
  flex-direction: column-reverse;
}
.pricing > .availabilityDate {
  background-color: var(--transaction-status-availability-background);
  color: var(--tile-hover-foreground-1);
  font: italic 14px var(--regular-font);
  justify-content: center;
  overflow: visible;
}
.pricing.purchasable {
  background-color: unset;
  padding: 0;
}
.pricing div {
  justify-content: center;
  user-select: none;
}

.overlay .pricing {
  align-items: center;
  margin-top: auto;
  overflow: visible;
}
.overlay .pricing + .buttonFX {
  margin-top: 10px;
}

.iconBar .pricing > .availabilityDate {
  background-color: var(--transaction-status-availability-background);
  color: var(--text-foreground-1);
  font: italic 14px var(--regular-font);
  justify-content: center;
  margin-bottom: 0;
  padding: 5px 10px;
}
.iconBar .pricing > .buttonFX > .button > .content {
  font-size: 16px;
}
.iconBar .pricing:not(.purchasable) {
  background-color: var(--transaction-status-purchased-background-card);
  margin: 0;
  padding: 5px 10px;
}

.episodePricing {
  display: flex;
  column-gap: 20px;
  overflow: visible;
}
