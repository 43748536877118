@keyframes heartElasticAppearing {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  66% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes heartElasticDisappearing {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  33% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
.modalContentDiv > .modalDialogDiv.card > .content {
  background-color: var(--card-background);
}
.modalContentDiv > .modalDialogDiv.card > .content > .modalHeader > .pictoElement > svg {
  fill: var(--card-foreground);
}
.modalContentDiv > .modalDialogDiv.card > .content > .modalHeader > .title {
  color: var(--card-foreground);
}

.card {
  --perspective: 1;
  --image-z-translation-u: -1px;
  --image-scale: 2.05;
  --mask-z-translation-u: -1px;
  --mask-scale-x: 2.5;
  --mask-scale-y: 3;
  height: 100%;
  width: min(100%, 1200px);
}
.card > .content > .slider {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100vh;
  /*
   * Horizontal overflow occurs because of the background image and perspective, but it should be hidden
   */
  overflow: hidden auto;
  position: relative;
}
.card > .content > .slider > .backgroundImage {
  animation: fadeIn var(--medium-duration) ease-in forwards;
  height: 100%;
  inset: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: -2;
}
.card > .content > .slider > .backgroundImage.image {
  background-repeat: no-repeat;
  background-size: contain;
  transition: background-image var(--short-duration) ease-out;
}
.card > .content > .slider > .backgroundImage.mask {
  background: linear-gradient(to bottom, transparent 40%, var(--background) 55%);
  z-index: -1;
}
.card > .content > .slider > .cover {
  display: none;
  z-index: 0;
}
.card > .content > .slider > .infoAndActionsContainer {
  align-items: flex-end;
  color: var(--text-foreground-1);
  display: flex;
  flex-direction: row;
  margin: 400px 0 20px;
  padding: 0 50px;
  width: 100%;
  z-index: 1;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-right: 50px;
  overflow: visible;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .channelImage {
  align-items: center;
  background: no-repeat center left;
  background-size: contain;
  display: flex;
  height: var(--channel-image-height-u);
  justify-content: flex-start;
  margin-right: 20px;
  width: var(--channel-image-width-u);
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: visible;
  position: relative;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text > .fullHeart {
  left: -34px;
  opacity: 0;
  position: absolute;
  top: 8px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text > .fullHeart.visible {
  opacity: 1;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text > .fullHeart.appearing {
  animation: heartElasticAppearing var(--medium-duration) ease-out forwards;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text > .fullHeart.disappearing {
  animation: heartElasticDisappearing var(--medium-duration) ease-out forwards;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text > .fullHeart > svg {
  fill: var(--favorite);
  stroke: var(--favorite);
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .text > .title {
  align-items: center;
  display: flex;
  font: 36px var(--bold-font);
  margin-bottom: 20px;
  text-align: left;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer {
  display: flex;
  flex-direction: column;
  font: 16px var(--regular-font);
  overflow: visible;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo {
  align-items: center;
  color: var(--text-foreground-2);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  overflow: visible;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo .pictoInfo {
  border-radius: var(--border-radius-tiny-u);
  font-size: 14px;
  padding: 2px 6px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div {
  align-items: center;
  display: flex;
  overflow: visible;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div:not(:last-child):not(.statusPicto)::after {
  background-color: var(--text-foreground-1);
  border-radius: 50%;
  content: "";
  height: 5px;
  margin: 0 5px;
  opacity: 0.7;
  width: 5px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div.languages > .pictoInfo {
  border: 1px solid var(--text-foreground-2);
  color: var(--text-foreground-2);
  font: 14px var(--semibold-font);
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div.languages > .pictoInfo:not(:last-child) {
  margin-right: 5px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div.languages > .pictoInfo.impaired {
  height: 25px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div.languages > .pictoInfo.impaired > svg {
  fill: var(--text-foreground-2);
  height: 16px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > div.parentalGuidance.pictoInfo {
  background-color: var(--text-foreground-1);
  color: var(--background);
  font: 15px var(--semibold-font);
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .statusPicto {
  margin-right: 12px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .liveRecording {
  margin-left: -10px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .liveRecording svg {
  width: 10px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .scheduledRecording {
  margin-left: -5px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .scheduledRecording > .pictoElement {
  margin-right: 5px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .recordError {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: -5px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .recordError > .pictoElement > svg {
  width: 20px;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .recordError > .description {
  align-items: center;
  font: 16px var(--regular-font);
  opacity: 0.8;
  text-decoration: underline;
  transition: var(--opacity-short);
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .programInfo > .recordError:hover > .description {
  opacity: 1;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .tagline {
  font: 18px var(--semibold-font);
  margin: 30px 0 0;
  text-align: left;
}
.card > .content > .slider > .infoAndActionsContainer > .infoContainer > .metadataContainer > .synopsis {
  font: 18px var(--light-font);
  line-height: 24px;
  margin-top: 10px;
  text-align: justify;
}
.card > .content > .slider > .infoAndActionsContainer > .iconBar {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 210px;
}
.card > .content > .slider.parallax {
  /*
   * In most cases, content will overflow the modal height, so the scrollbar
   * is displayed right at the beginning to prevent any layout shift
   */
  overflow-y: scroll;
  perspective: calc(var(--perspective) * 1px);
}
.card > .content > .slider.parallax > .backgroundImage.image {
  transform: translateZ(var(--image-z-translation-u)) scale(var(--image-scale));
}
.card > .content > .slider.parallax > .backgroundImage.mask {
  transform: translateZ(var(--mask-z-translation-u)) scale(var(--mask-scale-x), var(--mask-scale-y));
}
.card.portrait .content > .slider > .backgroundImage.image, .card.deeplink .content > .slider > .backgroundImage.image {
  background-position-y: -300px;
  background-size: contain;
}
.card.portrait .content > .slider > .backgroundImage.blurred, .card.deeplink .content > .slider > .backgroundImage.blurred {
  background-size: cover;
  filter: blur(30px) saturate(1.5);
}
.card.portrait .content > .slider > .backgroundImage.mask, .card.deeplink .content > .slider > .backgroundImage.mask {
  background: linear-gradient(to bottom, transparent 20%, var(--background) 60%);
}
.card.portrait .content > .slider > .cover, .card.deeplink .content > .slider > .cover {
  display: block;
  margin: 50px;
}
.card.portrait .content > .slider > .infoAndActionsContainer, .card.deeplink .content > .slider > .infoAndActionsContainer {
  margin: 0 0 20px;
}
.card.portrait .content > .slider > .infoAndActionsContainer > .infoContainer > .header, .card.deeplink .content > .slider > .infoAndActionsContainer > .infoContainer > .header {
  height: unset;
  margin: 20px 0 10px;
}
.card.portrait .content > .slider > .cover {
  height: var(--tile-portrait-image-height-u);
  width: var(--tile-portrait-image-width-u);
}
.card.deeplink .content > .slider > .cover {
  height: var(--tile-deeplink-image-height-u);
  width: var(--tile-deeplink-image-width-u);
}

@media (width <= 1200px) and (aspect-ratio >= 16/9), (width >= 1200px) and (height <= 700px) {
  .card > .content > .slider > .backgroundImage.image {
    background-size: cover;
  }
}
