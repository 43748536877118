.container {
  background-color: var(--live-progress-bar-background);
  bottom: 0;
  height: 5px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 320;
}
.container > .progressBar {
  background-color: var(--live-progress-bar-foreground);
  transform: scaleX(1);
  transform-origin: 0 center;
  transition: transform var(--long-duration) linear;
  width: 100%;
  will-change: transform;
}
