@charset "UTF-8";
/* stylelint-disable selector-class-pattern */
[data-tippy-root] {
  overflow: visible;
}
[data-tippy-root] .tippy-box {
  border-radius: var(--border-radius-huge-u);
  padding: 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05), inset 1px 1px rgba(255, 255, 255, 0.05);
}
[data-tippy-root] .tippy-box:has(.tooltipContent.carouselIndex) {
  border-radius: var(--tile-border-radius-u);
}
[data-tippy-root] .tippy-box > .tippy-content {
  flex: 0 1 auto;
  padding: 0;
}
[data-tippy-root] .tippy-box > .tippy-content > div {
  flex: 0 1 auto;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  margin: 10px;
  text-align: left;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex {
  margin: 0;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex > img {
  margin: 16px 16px 0;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex > .titleContainer {
  display: flex;
  flex-direction: column;
  font: 14px var(--regular-font);
  margin: 5px 16px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex.gemtv, [data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex.landscape {
  width: 192px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex.gemtv > img, [data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex.landscape > img {
  height: 90px;
  width: 160px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex.portrait {
  width: 144px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.carouselIndex.portrait > img {
  height: 166px;
  width: 112px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.button {
  padding-inline: 10px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary {
  align-items: flex-start;
  animation: fadeIn var(--medium-duration) ease-in forwards;
  display: flex;
  flex-direction: column;
  margin: 20px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary a {
  align-items: center;
  font: 14px var(--regular-font);
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary a > .pictoElement {
  width: 24px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary a > .pictoElement > svg {
  width: 12px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary .summary {
  font: 14px var(--light-font);
  margin: 20px 0;
  width: 400px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .detailsAndImage {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .detailsAndImage > .details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .detailsAndImage > .details > .name {
  font: 24px var(--semibold-font);
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .detailsAndImage > .details > .dates {
  font: 14px var(--regular-font);
  opacity: 0.8;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .detailsAndImage > img {
  margin-left: 10px;
  width: 100px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .citation {
  align-self: flex-end;
  font: 12px var(--regular-font);
  margin-top: 20px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .citation > a {
  font: 12px var(--semibold-font);
  margin-left: 5px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.castSummary > .citation > .license::before {
  content: "-";
  margin: 0 5px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.vodInformation {
  display: flex;
  flex-direction: column;
  margin: 20px 30px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.vodInformation > .bullet {
  font: 14px var(--regular-font);
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.vodInformation > .bullet::before {
  content: "■";
  margin-right: 10px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.vodInformation > .title {
  font: 20px var(--semibold-font);
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.vodInformation > .subtitle {
  font: 16px var(--semibold-font);
  margin: 20px 0 10px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.vodInformation > .customerService {
  font: 16px var(--semibold-font);
  margin-top: 40px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.npvrError {
  margin: 20px;
  font: 16px var(--semibold-font);
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.npvrError > .text.title {
  font: 16px var(--bold-font);
  margin-bottom: 10px;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.npvrError > .conflicts {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
}
[data-tippy-root] .tippy-box > .tippy-content .tooltipContent.npvrError > .conflicts > .conflictItem {
  margin-top: 5px;
}
[data-tippy-root] .tippy-box[data-theme=dark] {
  background-color: var(--tooltip-background);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent {
  color: var(--header-burger-menu-foreground);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary a:link, [data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary a:visited {
  color: var(--overlay-foreground-2);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary a:active, [data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary a:hover {
  color: var(--overlay-foreground-1);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary a svg {
  fill: var(--overlay-foreground-2);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary a:hover > svg {
  color: var(--overlay-foreground-1);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.castSummary > .citation {
  color: var(--overlay-foreground-2);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.npvrError > .text {
  color: var(--overlay-foreground);
}
[data-tippy-root] .tippy-box[data-theme=dark] > .tippy-content .tooltipContent.carouselIndex > .title {
  background-color: var(--overlay-transparent-background);
  color: var(--overlay-foreground);
}
[data-tippy-root] .tippy-box[data-theme=light] {
  background-color: var(--overlay-foreground-1);
}
[data-tippy-root] .tippy-box[data-theme=light] > .tippy-content .tooltipContent {
  color: var(--overlay-background);
}
[data-tippy-root] .tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
  transform: scale(0);
}
[data-tippy-root] .tippy-box[data-animation=fade][data-state=visible] {
  opacity: 1;
  transform: scale(1);
}

/* stylelint-enable selector-class-pattern */
