.episode.vod > .header {
  column-gap: 20px;
}
.episode.vod > .header > .title {
  flex-shrink: 1;
  font: 16px var(--bold-font);
  margin-right: auto;
  white-space: nowrap;
}
.episode.vod > .header > .actions {
  color: var(--text-foreground-1);
}
.episode.vod > .header > .actions .availabilityDate {
  font: italic 14px var(--regular-font);
  overflow: visible;
}
.episode.vod > .header > .actions .pricingLink {
  font: 14px var(--bold-font);
  opacity: 1;
  text-decoration: underline;
  transition: var(--opacity-short);
}
.episode.vod > .header > .actions .pricingLink:hover {
  opacity: 0.6;
}
.episode.vod > .content > .cover {
  height: 160px;
  width: 108px;
}
.episode.vod .purchaseStatus {
  color: var(--accent);
  font: 14px var(--semibold-font);
}
