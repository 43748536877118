.synopsisMeasure {
  font: var(--tile-synopsis-font-size-u) var(--light-font);
  left: -1000px;
  letter-spacing: 0.1pt;
  line-height: var(--tile-synopsis-line-height-u);
  position: absolute;
  top: -1000px;

  &.tagLine {
    font-size: var(--tile-synopsis-tagline-font-size-u);
    line-height: var(--tile-synopsis-tagline-line-height-u);
  }
}

.sectionItem {
  > .tileContainer {
    > .overlay {
      align-items: stretch;
      background-color: var(--dimmer);
      border-radius: var(--tile-border-radius-u);
      color: var(--tile-hover-foreground-1);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: stretch;
      opacity: 0;
      overflow: visible;
      padding: 10px 15px 15px;
      position: absolute;
      text-align: left;
      transition: var(--opacity-short);
      user-select: none;
      width: 100%;
      will-change: opacity;
      z-index: 350;

      &.bottom {
        justify-content: flex-end;
      }

      > .loader {
        align-self: center;
        flex-grow: 1;

        > svg {
          fill: var(--text-foreground-1);
        }
      }

      .text {
        display: flex;
        font: 18px var(--bold-font);
        max-height: 24px;

        &.secondLineInfo,
        &.thirdLineInfo {
          color: var(--tile-hover-foreground-2);
          font: 11px var(--light-font);
          max-height: 16px;
        }

        &.thirdLineInfo {
          margin-bottom: 5px;
        }
      }

      > .details {
        align-items: center;
        color: var(--tile-hover-foreground-1);
        display: flex;
        flex-wrap: wrap;
        font: 12px var(--regular-font);
        opacity: 0.9;
        text-align: left;

        &.other {
          margin: 5px 0;
        }

        .pictoInfo {
          border-radius: var(--border-radius-tiny-u);
          font-size: 10px;
          padding: 1px 4px;
        }

        > div {
          align-items: center;
          display: flex;

          &:not(:last-child) {
            &::after {
              background-color: var(--tile-hover-foreground-1);
              border-radius: 50%;
              content: '';
              height: 5px;
              margin: 0 5px;
              opacity: 0.7;
              width: 5px;
            }
          }

          &.languages {
            > .pictoInfo {
              border: 1px solid var(--tile-hover-foreground-1);

              &:not(:last-child) {
                margin-right: 5px;
              }

              &.impaired {
                padding-inline: 4px;
                height: 18px;

                > svg {
                  fill: var(--text-foreground-1);
                  height: 12px;
                }
              }
            }
          }

          &.parentalGuidance {
            &.pictoInfo {
              background-color: var(--tile-hover-foreground-1);
              color: var(--tile-parental-guidance-foreground);
              font: 11px var(--bold-font);
            }
          }
        }

        & + .buttonFX {
          margin-top: auto;
        }
      }

      > .iconBar {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 10px;
        position: absolute;
        right: -24px;
        top: 5px;

        > .iconContainer {
          background-color: var(--overlay-transparent-background);
          border-radius: var(--border-radius-huge-u);
          margin-bottom: 4px;

          > .pictoElement {
            > svg {
              fill: var(--overlay-icon);
              width: 18px;
            }

            &.fullHeart {
              > svg {
                fill: var(--favorite);
                stroke: var(--favorite);
              }
            }

            &.active {
              svg {
                fill: var(--favorite);
              }
            }

            &.moreInfo {
              > svg {
                margin: 10px;
              }
            }
          }
        }
      }

      .resizeDetectorSynopsis {
        margin-block: 4px 10px;
      }

      .synopsisWrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        overflow: visible;

        &.empty {
          margin: 0;
        }

        &.tagLine {
          .synopsis > .text,
          .ellipsis {
            font-size: var(--tile-synopsis-tagline-font-size-u);
            line-height: var(--tile-synopsis-tagline-line-height-u);
          }
        }
      }

      .synopsis,
      .ellipsis {
        font: var(--tile-synopsis-font-size-u) var(--light-font);
        letter-spacing: 0.1pt;
        line-height: var(--tile-synopsis-line-height-u);
      }

      .synopsis {
        > .text {
          flex: 1;
          font: var(--tile-synopsis-font-size-u) var(--light-font);
          line-height: var(--tile-synopsis-line-height-u);
          max-height: unset;

          /*
           * Synopsis text should be truncated when the ellipsis [...] is shown
           */
          overflow: hidden;
        }
      }

      .ellipsis {
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }

      .buttonFX {
        > .button {
          > .content {
            font-size: 12px;
          }
        }
      }
    }
  }
}
