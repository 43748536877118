.backButtonBase {
  background-color: transparent;
  border: 0;
}
.backButtonBase:hover > .pictoElement.hoverEffect > svg {
  opacity: 1;
  transform: scale(1);
}
.backButtonBase:hover > .text {
  opacity: 1;
}
.backButtonBase > .text {
  color: var(--text-foreground-1);
  font: 18px var(--semibold-font);
  margin-left: 10px;
  opacity: 0.7;
  transition: var(--opacity-short);
  user-select: none;
}
.backButtonBase > .pictoElement > svg {
  opacity: 0.7;
  transition: var(--opacity-short), transform var(--short-duration) ease-out;
  transform: scale(0.9);
  width: 30px;
}
