@keyframes headerSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes headerSlideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
.mainLayout > .header {
  align-items: center;
  /* stylelint-disable */
  background-image: linear-gradient(var(--header-background-top-color), var(--header-background-bottom-color));
  /* stylelint-enable */
  display: flex;
  flex-direction: row;
  height: var(--header-height-u);
  justify-content: flex-start;
  opacity: 0;
  overflow: visible;
  position: fixed;
  transform: translateY(-100%);
  /* stylelint-disable */
  transition: --header-background-bottom-color var(--medium-duration), --header-background-top-color var(--medium-duration);
  /* stylelint-enable */
  width: 100%;
  z-index: 350;
}
.mainLayout > .header.slideDown {
  animation: headerSlideDown var(--short-duration) ease-out forwards;
}
.mainLayout > .header.slideUp {
  animation: headerSlideUp var(--short-duration) ease-in forwards;
}
.mainLayout > .header.scroll {
  --header-background-bottom-color: var(--header-background-scroll-bottom-color);
  --header-background-top-color: var(--header-background-scroll-top-color);
}
.mainLayout > .header:hover {
  --header-background-bottom-color: var(--header-background-hover-bottom-color);
  --header-background-top-color: var(--header-background-hover-top-color);
}
.mainLayout > .header > .headerLeft {
  align-items: center;
}
.mainLayout > .header > .headerLeft > .headerImage {
  cursor: pointer;
  height: var(--header-logo-height-u);
  margin: 0 50px;
  pointer-events: auto;
  user-select: none;
  width: var(--header-logo-width-u);
}
.mainLayout > .header > .headerMiddle {
  align-items: center;
  flex: 1 1 auto;
  min-width: var(--burger-width-u);
  overflow: visible;
  position: relative;
}
.mainLayout > .header > .headerRight {
  align-items: center;
  display: flex;
  margin: 0 50px;
}
.mainLayout > .header > .headerRight > a,
.mainLayout > .header > .headerRight > .link {
  border-bottom: 2px solid var(--accent);
  color: var(--accent);
  cursor: pointer;
  font-family: var(--bold-font);
  text-decoration: none;
}
.mainLayout > .header > .headerRight > a:hover,
.mainLayout > .header > .headerRight > .link:hover {
  color: var(--accent-light);
  border-color: var(--accent-light);
}
.mainLayout > .header > .headerRight > .searchBox {
  margin: 0 10px;
}
.mainLayout > .header > .headerRight > .freeTrial {
  align-items: center;
  background-color: #f00;
  border-radius: var(--border-radius-tiny-u);
  display: flex;
  height: 44px;
  justify-content: center;
  margin-right: 20px;
  padding-inline: 20px;
  position: relative;
  color: #fff;
}
.mainLayout > .header > .headerRight > .freeTrial:hover {
  background-color: #ff1a48;
}
