@import 'BaseEpisode.css';
.seriesSection {
  --navigation-button-size-u: 126;
  --navigation-button-collapsed-position-u: var(--navigation-button-size-u) / 4;
  --navigation-button-expanded-position-u: var(--navigation-button-size-u) / 8;
  --navigation-button-picto-margin-u: var(--navigation-button-collapsed-position-u) / 2;
  display: flex;
  flex-direction: column;
  margin: 30px 0 50px;
  overflow: visible;
  padding: 0 50px;
  pointer-events: auto;
  width: 100%;
}
.seriesSection > .header {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  padding-right: 20px;
}
.seriesSection > .header > .sectionTitleContainer {
  align-items: center;
  display: flex;
}
.seriesSection > .header > .sectionTitleContainer > .sectionTitle {
  align-items: center;
  color: var(--text-foreground-1);
  font: 18px var(--bold-font);
  margin-left: 5px;
  user-select: none;
}
.seriesSection > .header > .sectionTitleContainer > .sectionTitle > .episodeCount {
  color: var(--text-foreground-2);
  margin-left: 5px;
}
.seriesSection.placeholder > .header .sectionTitleContainer.noTitle {
  background-color: var(--placeholder-text);
  height: 17px;
  width: 250px;
}
.seriesSection .episodes {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: visible;
  row-gap: 4px;
}
