@import 'VodEpisode.css';
.seriesSection.placeholder .episodes > .episode.vod > .header {
  height: 48px;
}
.seriesSection.placeholder .episodes > .episode.vod > .header .empty {
  background-color: var(--placeholder-text);
  height: 20px;
  margin-left: 5px;
  width: 300px;
}
.seriesSection.placeholder .episodes > .episode.vod > .header .pricingLink {
  background-color: var(--placeholder-text);
  height: 20px;
  margin-left: 20px;
  width: 160px;
}
