@keyframes circleLoaderArcSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.infiniteCircleLoaderArc {
  align-items: center;
  animation: circleLoaderArcSpin 1s infinite linear;
  aspect-ratio: 1;
  border: 10px solid rgba(255, 255, 255, 0.2);
  border-left: 10px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
  transform: translateZ(0);
  width: 100px;
}
