.modalDialogDiv.avenue {
  width: 60%;
}
.modalDialogDiv.avenue > .content > .avenueView {
  flex: 1;
  overflow-y: auto;
}
.modalDialogDiv.avenue > .content > .avenueView > .slider {
  margin-top: 0;
}
.modalDialogDiv.avenue.explore > .content {
  background-color: var(--explorer-background);
  color: var(--explorer-foreground);
}
.modalDialogDiv.avenue.explore > .content .avenueView > .slider > .section:first-child {
  margin-top: 30px;
}
.modalDialogDiv.avenue.explore > .content .channelGroupSection > .header,
.modalDialogDiv.avenue.explore > .content .sectionGrid > .header {
  color: inherit;
}
.modalDialogDiv.avenue.explore > .content .channelGroupSection > .header .sectionTitleContainer,
.modalDialogDiv.avenue.explore > .content .sectionGrid > .header .sectionTitleContainer {
  color: inherit;
}

@media (width <= 1600px) {
  .modalDialogDiv.avenue {
    width: 80%;
  }
}
@media (width <= 1400px) {
  .modalDialogDiv.avenue {
    width: 90%;
  }
}
@media (width <= 1000px) {
  .modalDialogDiv.avenue {
    width: 100%;
  }
}
