.sectionCarousel {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 440px;
  justify-content: center;
  opacity: 1;
  padding: 0 20px;
  pointer-events: none;
  transition: var(--opacity-medium);
}
.sectionCarousel.hidden {
  opacity: 0;
}
.sectionCarousel .previousButton,
.sectionCarousel .nextButton {
  align-items: center;
  display: flex;
  height: 100%;
  opacity: 0.4;
  pointer-events: auto;
  width: 30px;
}
.sectionCarousel .previousButton > .pictoElement,
.sectionCarousel .nextButton > .pictoElement {
  height: 20px;
}
.sectionCarousel .previousButton:hover,
.sectionCarousel .nextButton:hover {
  opacity: 1;
}
.sectionCarousel > .paginationAndActions {
  align-self: flex-end;
  flex-direction: column;
  pointer-events: auto;
}
.sectionCarousel > .paginationAndActions > .pagination {
  display: flex;
  flex: 1;
  margin-bottom: 25px;
  opacity: 0.8;
}
.sectionCarousel > .paginationAndActions > .pagination > .itemIndex {
  cursor: pointer;
}
.sectionCarousel > .paginationAndActions > .iconBar {
  align-items: flex-end;
  flex-direction: row;
  height: 20px;
}
.sectionCarousel > .paginationAndActions > .iconBar .pictoElement {
  width: 20px;
}
.sectionCarousel > .paginationAndActions > .iconBar .pictoElement > svg {
  width: 14px;
}
.sectionCarousel > .swipeable {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  overflow: visible;
  pointer-events: auto;
}
.sectionCarousel > .swipeable > .itemSlideContainer {
  align-items: center;
  background: radial-gradient(closest-side at 250px 200px, rgba(0, 0, 0, 0.25), transparent);
  flex: 1 1 100%;
  height: 100%;
  overflow: visible;
  pointer-events: none;
  position: relative;
}
.sectionCarousel > .swipeable > .itemSlideContainer > .hubImage {
  height: auto;
  padding-left: 100px;
  position: absolute;
  top: -80px;
  width: auto;
}
