.imageCarousel {
  height: 660px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.imageCarousel > .imageContainer {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: var(--opacity-long);
  width: 100%;
}
.imageCarousel > .imageContainer.selected {
  opacity: 1;
}
