.loginHeader {
  align-items: center;
  background-color: var(--header-background);
  display: flex;
  height: 58px;
  width: 100%;
  z-index: 10;
}
.loginHeader > img {
  height: var(--header-logo-height-u);
  margin-left: 50px;
  user-select: none;
  width: var(--header-logo-width-u);
}
.loginHeader > .pictoElement {
  border-radius: 50%;
  height: 40px;
  margin: 0 10px 0 auto;
  width: 40px;
}

.runningOnMobile {
  align-items: center;
  background-color: var(--background);
  background-repeat: no-repeat;
  color: var(--text-foreground-1);
  display: flex;
  flex-direction: column;
  font: 16px var(--regular-font);
  justify-content: center;
  inset: 0;
  padding: 20px;
  position: absolute;
}
.runningOnMobile > img {
  height: 200px;
  margin-bottom: 50px;
  user-select: none;
}
.runningOnMobile > img + div {
  margin-bottom: 50px;
  text-align: center;
}
.runningOnMobile > a {
  margin-bottom: 10px;
  text-decoration: none;
}
.runningOnMobile > a.text {
  background-color: var(--text-foreground-1);
  border-radius: var(--border-radius-tiny-u);
  color: var(--background);
  font: 16px var(--semibold-font);
  margin-top: 40px;
  padding: 10px 20px;
}

.browserNotCompatible {
  align-items: center;
  color: var(--text-foreground-1);
  display: flex;
  flex-direction: column;
  font: 24px var(--regular-font);
  margin: auto 20px;
}
.browserNotCompatible > img {
  height: 200px;
  margin-bottom: 50px;
  user-select: none;
}
.browserNotCompatible > .browsers {
  align-self: stretch;
  justify-content: space-evenly;
  margin: 20px 0 40px;
}
.browserNotCompatible > .browsers + div {
  margin-bottom: 20px;
}
.browserNotCompatible a {
  margin-bottom: 10px;
  text-decoration: none;
}
.browserNotCompatible a:link, .browserNotCompatible a:visited {
  color: var(--accent);
}
.browserNotCompatible a:active, .browserNotCompatible a:hover {
  color: var(--accent-light);
}

.appLoader {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.startupError {
  align-items: center;
  color: var(--text-foreground-1);
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
  text-align: center;
}
.startupError > .errorTitle {
  align-items: center;
  font: 24px var(--bold-font);
}
.startupError > .errorName {
  font: 18px var(--regular-font);
}
.startupError > .errorHint {
  font: 16px var(--regular-font);
  margin-top: 50px;
}

@media (width <= 768px) {
  .browserNotCompatible {
    font: 18px var(--regular-font);
  }
}
@media (width <= 400px) {
  .browserNotCompatible {
    font: 14px var(--regular-font);
  }
  .browserNotCompatible > svg {
    margin-bottom: 40px;
    width: 120px;
  }
}
