/* stylelint-disable selector-class-pattern */
.Toastify > .Toastify__toast-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  overflow: visible;
  top: 60px;
  z-index: 11000;
}
.Toastify > .Toastify__toast-container > .Toastify__toast {
  background-color: var(--background);
  border-radius: var(--border-radius-tiny-u);
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0 1px 10px 0 var(--shadow), 0 2px 15px 0 var(--shadow);
  color: var(--text-foreground-1);
  overflow: visible;
  padding-left: 40px;
  width: 300px;
}
.Toastify > .Toastify__toast-container > .Toastify__toast.large {
  width: 400px;
}
.Toastify > .Toastify__toast-container > .Toastify__toast > .Toastify__toast-body {
  display: block;
  flex: 1 1 auto;
  font: 16px var(--regular-font);
  margin-right: 5px;
}
.Toastify > .Toastify__toast-container > .Toastify__toast > .Toastify__toast-body > div {
  flex-direction: column;
}
.Toastify > .Toastify__toast-container > .Toastify__toast > .Toastify__toast-body > div > div:last-child {
  margin-top: 10px;
}
.Toastify > .Toastify__toast-container > .Toastify__toast > .Toastify__toast-body div {
  flex: 1 1 auto;
}
.Toastify > .Toastify__toast-container > .Toastify__toast::before {
  border-radius: 50%;
  content: "";
  height: 40px;
  left: -24px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
}
.Toastify > .Toastify__toast-container > .Toastify__toast::after {
  align-items: center;
  background-color: var(--notification-picto-background-overlay);
  border-radius: 50%;
  display: flex;
  color: #fff;
  content: "";
  justify-content: center;
  left: -20px;
  height: 32px;
  margin-top: -16px;
  position: absolute;
  top: 50%;
  width: 32px;
}
.Toastify > .Toastify__toast-container > .Toastify__toast > .Toastify__progress-bar {
  border-radius: 0 0 var(--border-radius-tiny-u) var(--border-radius-tiny-u);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--error::before {
  background-color: var(--error);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--error::after {
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 240' xmlns='http://www.w3.org/2000/svg' fill='white' width='24px' height='24px' style='margin-top: 4px'%3E%3Cpath d='M28.89,47.86c-6.14-6.13-5.39-13.24,1-19.61,6.13-6.13,13.47-7.11,19.61-1L101,78.74l49.27-49.26c6.37-6.38,13.48-5.64,19.6.48,6.38,6.38,7.36,13.73,1,20.1L121.54,99.33l50.74,50.74c6.36,6.37,5.14,13.47-1,19.6-6.37,6.37-13.24,7.35-19.6,1L101,119.92,49.72,171.15c-6.37,6.37-13.72,5.4-20.1-1-6.12-6.12-6.86-13.23-.49-19.6L80.36,99.33Z' /%3E%3C/svg%3E");
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--error > .Toastify__progress-bar {
  background-color: var(--error);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--info::before {
  background-color: var(--info);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--info::after {
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 240' xmlns='http://www.w3.org/2000/svg' fill='white' width='24px' height='24px' style='margin-top: 4px'%3E%3Cpath d='M1156.28-278.29c-35.37-1-62.91-14.61-83.59-41.41a3.24,3.24,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.62-54.12,39.67-5.14,78.73,18,93.36,54.47a2.83,2.83,0,0,1-.42,3.15c-17,22.32-39.37,36-67.59,40.35-4.93.75-10,.95-13.9,1.32' /%3E%3Cpath d='M1196.82-436.56a41.72,41.72,0,0,1-41.9,41.6A41.78,41.78,0,0,1,1113.48-437c.11-22.73,19-41.35,41.91-41.26a41.8,41.8,0,0,1,41.43,41.73' /%3E%3Cpath d='M1156.28-278.29c-35.37-1-62.91-14.61-83.59-41.41a3.24,3.24,0,0,1-.48-3.76c14-31.1,38.09-49.66,72.62-54.12,39.67-5.14,78.73,18,93.36,54.47a2.83,2.83,0,0,1-.42,3.15c-17,22.32-39.37,36-67.59,40.35-4.93.75-10,.95-13.9,1.32' /%3E%3Cpath d='M1196.82-436.56a41.72,41.72,0,0,1-41.9,41.6A41.78,41.78,0,0,1,1113.48-437c.11-22.73,19-41.35,41.91-41.26a41.8,41.8,0,0,1,41.43,41.73' /%3E%3Cpath d='M102.07.06A99.87,99.87,0,0,0,0,99.85C-.25,155.08,44.71,200,99.88,200,154.54,200.57,200,155.23,200,100.4A100,100,0,0,0,102.07.06Zm-2,182.33c-45.66,0-82.84-36.9-82.45-82.57s36.7-82.79,84-82.17c44.67.59,81,36.9,80.86,83.18C182.28,145.86,145.47,182.39,100,182.39Z' /%3E%3Cpath d='M90.47,62.72a3.45,3.45,0,0,1-1-2.54v-11a3.62,3.62,0,0,1,1-2.69A3.47,3.47,0,0,1,93,45.48h13.8a3.6,3.6,0,0,1,3.76,3.74v11a3.32,3.32,0,0,1-1.14,2.54,3.7,3.7,0,0,1-2.62,1.05H93A3.47,3.47,0,0,1,90.47,62.72Zm.61,90.75a3.42,3.42,0,0,1-1-2.55V80.12a3.36,3.36,0,0,1,1-2.62,3.57,3.57,0,0,1,2.54-1h12.76A3.58,3.58,0,0,1,110,80.12v70.8a3.58,3.58,0,0,1-3.59,3.6H93.62A3.43,3.43,0,0,1,91.08,153.47Z' /%3E%3C/svg%3E");
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--info > .Toastify__progress-bar {
  background-color: var(--info);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--success::before {
  background-color: var(--success);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--success::after {
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 240' xmlns='http://www.w3.org/2000/svg' fill='white' width='24px' height='24px' style='margin-top: 4px'%3E%3Cpath d='M67.93,171.64l-65-65a10,10,0,0,1,0-14.14L17.07,78.36a10,10,0,0,1,14.14,0L75,122.14l93.79-93.78a10,10,0,0,1,14.14,0L197.07,42.5a10,10,0,0,1,0,14.14l-115,115a10,10,0,0,1-14.14,0Z' /%3E%3C/svg%3E");
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--success > .Toastify__progress-bar {
  background-color: var(--success);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--warning::before {
  background-color: var(--warning);
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--warning::after {
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 240' xmlns='http://www.w3.org/2000/svg' fill='white' width='24px' height='24px' style='margin-top: 4px'%3E%3Cpath d='M99,13.15.84,186.05a1,1,0,0,0,.87,1.49l196.57.83a1,1,0,0,0,.87-1.49L100.76,13.15A1,1,0,0,0,99,13.15Z' /%3E%3Cpath fill='gold' d='M91.39,141a5.37,5.37,0,0,1,3.15-2.73,22,22,0,0,1,6.59-.72c3,0,5.16.66,6.5,2s2,3.37,2,6.18v1.65a31.58,31.58,0,0,1-.16,3.66,9.23,9.23,0,0,1-1,2.84q-1.65,3-7.88,3t-8.25-2q-2-2-2-7.78A13.79,13.79,0,0,1,91.39,141Zm13.81-12a21.55,21.55,0,0,1-5.25.51,21.7,21.7,0,0,1-5.26-.51A4.18,4.18,0,0,1,92,127.1a7.21,7.21,0,0,1-.93-2.37,24.31,24.31,0,0,1-.15-3.19V88.87A25.93,25.93,0,0,1,91,85.62a6.68,6.68,0,0,1,1-2.42,4.57,4.57,0,0,1,2.79-1.9,19.38,19.38,0,0,1,5.25-.57,19.51,19.51,0,0,1,5.26.57,4.31,4.31,0,0,1,2.73,2,7.76,7.76,0,0,1,.93,2.48,25.64,25.64,0,0,1,.15,3.24v32.67a24.6,24.6,0,0,1-.15,3.2,6.2,6.2,0,0,1-1,2.31A4.43,4.43,0,0,1,105.2,129Z' /%3E%3C/svg%3E");
}
.Toastify > .Toastify__toast-container > .Toastify__toast.Toastify__toast--warning > .Toastify__progress-bar {
  background-color: var(--warning);
}
.Toastify > .Toastify__toast-container > .Toastify__toast > .Toastify__close-button > svg {
  fill: var(--text-foreground-1);
}

/* stylelint-enable selector-class-pattern */
