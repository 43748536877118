.searchHistoryTerm {
  align-items: center;
  color: var(--text-foreground-1);
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
}
.searchHistoryTerm .pictoElement {
  height: 20px;
  width: 18px;
}
.searchHistoryTerm .pictoElement > svg {
  height: 16px;
}
.searchHistoryTerm > .refresh {
  opacity: 0;
  transition: var(--opacity-short);
}
.searchHistoryTerm > .refresh > svg {
  fill: var(--text-foreground-1);
}
.searchHistoryTerm > .term {
  background-color: var(--search-avenue-history-background);
  border-radius: var(--border-radius-huge-u);
  cursor: pointer;
  margin: 0 5px;
  padding: 14px 22px;
}
.searchHistoryTerm > .term:hover {
  background-color: var(--search-avenue-history-background-hover);
}
.searchHistoryTerm > .term:hover + .refresh {
  opacity: 1;
}
.searchHistoryTerm > .delete > .pictoElement > svg {
  fill: var(--text-foreground-1);
}
