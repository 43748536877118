.registrationFrameContainer {
  flex-grow: 1;
}
.registrationFrameContainer > .debugPanel {
  background-color: var(--accent-light);
  box-shadow: 0 0 4px 0 #000;
  color: var(--text-foreground-1);
  display: flex;
  flex-direction: column;
  font: 14px var(--regular-font);
  overflow: visible;
  padding: 10px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.registrationFrameContainer > .debugPanel > .overriddenAppConf {
  align-items: center;
  display: flex;
  font: 16px var(--regular-font);
  margin: 10px 0;
  padding: 0 20px;
  width: 100%;
  z-index: 1000;
}
.registrationFrameContainer > .debugPanel > .overriddenAppConf > .label {
  background-color: var(--text-foreground-1);
  border-radius: var(--border-radius-tiny-u) 0 0 var(--border-radius-tiny-u);
  color: var(--background);
  padding: 1px 5px;
}
.registrationFrameContainer > .debugPanel > .overriddenAppConf > .value {
  background-color: var(--background);
  border-radius: 0 var(--border-radius-tiny-u) var(--border-radius-tiny-u) 0;
  color: var(--text-foreground-1);
  font-family: var(--bold-font);
  padding: 1px 5px;
}
.registrationFrameContainer > .debugPanel.reduced {
  padding: 0;
}
.registrationFrameContainer > .debugPanel.reduced > .overriddenAppConf {
  font-size: 14px;
  margin: 5px 0;
}
.registrationFrameContainer > .debugPanel * {
  overflow: visible;
}
.registrationFrameContainer > .debugPanel > .separator {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px 0;
}
.registrationFrameContainer > .debugPanel > .row {
  align-items: center;
  margin: 10px 0;
  padding: 0 20px;
}
.registrationFrameContainer > .debugPanel > .row.buttons {
  justify-content: center;
}
.registrationFrameContainer > .debugPanel > .row.buttons > div {
  margin-inline: 20px;
}
.registrationFrameContainer > .debugPanel > .row > .label {
  font-family: var(--semibold-font);
  margin-right: 10px;
}
.registrationFrameContainer > .debugPanel > .row > input {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0;
  border-radius: var(--border-radius-tiny-u);
  color: var(--text-foreground-1);
  flex-grow: 1;
  padding: 5px 10px;
  user-select: text;
}
.registrationFrameContainer > .debugPanel > .row > input::-moz-selection {
  background: #fff;
  color: var(--text-foreground-1);
}
.registrationFrameContainer > .debugPanel > .row > input::selection {
  background: #fff;
  color: var(--text-foreground-1);
}
.registrationFrameContainer > .registrationFrame {
  border: 0;
  height: 100%;
  width: 100%;
}
