.resizeDetector {
  inset: 0;
  position: absolute;
  pointer-events: none;
}
.resizeDetector.withChildren {
  flex: 1 0 auto;
  inset: initial;
  pointer-events: all;
  position: relative;
}
