:root {
  /************************
   Sizes (all in px)
  *************************/
  --header-height-u: 58px;
  --header-logo-height-u: 30px;
  --header-logo-width-u: 164px;
  --burger-width-u: 66px;
  --npvr-large-container-width-u: 1200px;
  --border-radius-tiny-u: 2px;
  --border-radius-medium-u: 14px;
  --border-radius-big-u: 18px;
  --border-radius-huge-u: 28px;
  --default-font: sans-serif;
  --monospace: monospace;
  --light-font: OpenSansLight, var(--default-font, sans-serif);
  --regular-font: OpenSansRegular, var(--default-font, sans-serif);
  --semibold-font: OpenSansSemiBold, var(--default-font, sans-serif);
  --bold-font: OpenSansBold, var(--default-font, sans-serif);
  --black-font: OpenSansBlack, var(--default-font, sans-serif);
  --tile-gemtv-image-width-u: 320px;
  --tile-gemtv-image-height-u: 180px;
  --tile-gemtv-horizontal-space-u: 24px;
  --tile-portrait-image-width-u: 200px;
  --tile-portrait-image-height-u: 296px;
  --tile-portrait-horizontal-space-u: 24px;
  --tile-deeplink-image-width-u: 248px;
  --tile-deeplink-image-height-u: var(--tile-portrait-image-height-u);
  --tile-deeplink-horizontal-space-u: 24px;
  --tile-deeplink-5x1-image-width-u: 1770px;
  --tile-deeplink-5x1-image-height-u: 354px;
  --tile-deeplink-5x1-horizontal-space-u: 24px;
  --tile-deeplink-3x1-image-width-u: 873px;
  --tile-deeplink-3x1-image-height-u: 291px;
  --tile-deeplink-3x1-horizontal-space-u: 24px;
  --tile-landscape-image-width-u: 320px;
  --tile-landscape-image-height-u: 180px;
  --tile-landscape-horizontal-space-u: 24px;
  --tile-landscape-big-image-width-u: 455px;
  --tile-landscape-big-image-height-u: 256px;
  --tile-landscape-big-horizontal-space-u: 24px;
  --tile-landscape-vod-image-width-u: 478px;
  --tile-landscape-vod-image-height-u: 268px;
  --tile-landscape-vod-horizontal-space-u: 24px;
  --tile-channel-image-width-u: 96px;
  --tile-channel-image-height-u: 60px;
  --tile-channel-group-image-width-u: 153px;
  --tile-channel-group-image-height-u: 115px;
  --tile-channel-group-horizontal-space-u: 24px;
  --tile-channel-group-big-image-width-u: 200px;
  --tile-channel-group-big-image-height-u: 165px;
  --tile-channel-group-big-horizontal-space-u: 24px;
  --avenue-image-width-u: 100px;
  --avenue-image-height-u: 100px;
  --npvr-tv-image-width-u: 178px;
  --npvr-tv-image-height-u: 100px;
  --selection-thickness-u: 8px;
  --tile-border-radius-u: 14px;
  --tile-selection-border-radius-u: 18px;
  --tile-synopsis-font-size-u: 12px;
  --tile-synopsis-line-height-u: 14px;
  --tile-synopsis-tagline-font-size-u: 14px;
  --tile-synopsis-tagline-line-height-u: 16px;
  --tile-text-height-u: 41px;
  --channel-list-item-width-u: 120px;
  --channel-list-item-height-u: 60px;
  --channel-image-width-u: 80px;
  --channel-image-height-u: 40px;
  --tile-channel-logo-width-u: 50px;
  --tile-channel-logo-height-u: 25px;
  --conflict-channel-image-width-u: 40px;
  --conflict-channel-image-height-u: 20px;
  --modal-inline-padding-u: 50px;
  /************************
   Transitions & animations
  *************************/
  --short-duration: 0.3s;
  --medium-duration: 0.5s;
  --long-duration: 1s;
  --opacity-short: opacity var(--short-duration) ease-out;
  --opacity-medium: opacity var(--medium-duration) ease-out;
  --opacity-long: opacity var(--long-duration) ease-out;
}

/************************
 Animations
*************************/
@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
