.options {
  --option-picto-height: 22px;
  align-items: center;
  background-color: var(--player-overlay-background);
  bottom: 50px;
  display: grid;
  grid-template-columns: [picto-start] 30px [picto-end title-start] auto [title-end selection-start] auto [selection-end];
  overflow: visible;
  position: absolute;
  right: 0;
  z-index: 700;
}
.options .pictoElement > svg {
  fill: var(--player-overlay-foreground-1);
  height: var(--option-picto-height);
}
.options .cell {
  cursor: pointer;
  height: 100%;
  padding: 12px 10px;
  width: 100%;
}
.options > .selection {
  display: contents;
}
.options > .selection div {
  user-select: none;
  z-index: 740;
}
.options > .selection > .pictoElement {
  grid-column: picto-start/picto-end;
}
.options > .selection > .pictoElement > svg {
  transition: transform var(--short-duration) ease-out;
}
.options > .selection:hover > div {
  background-color: var(--player-overlay-background-hover);
}
.options > .selection.highlighted > div {
  background-color: var(--player-overlay-background-active);
}
.options > .selection.highlighted > .pictoElement svg {
  transform: rotate(-180deg);
}
.options > .selection > .selectionTitle {
  font: 16px var(--semibold-font);
  grid-column: title-start/title-end;
}
.options > .selection > .selectionInfo {
  color: var(--player-overlay-foreground-2);
  font: 16px var(--regular-font);
  grid-column: selection-start/selection-end;
}
.options > .selection.greenStreaming {
  display: contents;
}
.options > .selection.greenStreaming > .selectionTitle {
  grid-column: title-start/selection-end;
}
.options > .selection.greenStreaming > .selectionTitle > .leaf {
  border: 1px solid var(--player-overlay-foreground-2);
  border-radius: var(--border-radius-tiny-u);
  height: var(--option-picto-height);
  margin-left: 5px;
}
.options > .selection.greenStreaming > .pictoElement {
  bottom: unset;
  left: unset;
}
.options > .optionsContainer {
  background-color: var(--player-overlay-background);
  display: grid;
  left: 0;
  grid-template-columns: [picto-start] 30px [picto-end title-start] auto [title-end];
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(0);
  transition: var(--opacity-short), transform var(--short-duration) ease-out;
  z-index: 620;
}
.options > .optionsContainer.visible {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-100%);
}
.options > .optionsContainer > .option {
  display: contents;
}
.options > .optionsContainer > .option:hover > div {
  background-color: var(--player-overlay-background-hover);
}
.options > .optionsContainer > .option .pictoElement {
  grid-column: picto-start/picto-end;
}
.options > .optionsContainer > .option .pictoElement.impaired {
  height: 18px;
}
.options > .optionsContainer > .option > .optionLabel {
  align-items: center;
  color: var(--player-overlay-foreground-1);
  font: 16px var(--regular-font);
  grid-column: title-start/title-end;
}
