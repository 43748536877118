.modalDialogDiv.newVersion > .content > .header {
  padding-top: 50px;
}
.modalDialogDiv.newVersion > .content > .newVersionContent {
  align-items: center;
  color: var(--overlay-foreground-1);
  display: flex;
  flex-direction: column;
  font: 16px var(--regular-font);
  padding: 20px var(--modal-inline-padding-u) 30px;
}
.modalDialogDiv.newVersion > .content > .newVersionContent > div:first-child {
  margin-bottom: 20px;
}
