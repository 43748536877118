.section.placeholder .sectionSlider > .sectionItem {
  margin-right: var(--tile-gemtv-horizontal-space-u);
  width: var(--tile-gemtv-image-width-u);
}
.section.placeholder .sectionSlider > .sectionItem > .tileContainer {
  height: var(--tile-gemtv-image-height-u);
}
.section.placeholder .sectionSlider > .sectionItem > .textContainer > .text {
  background-color: var(--placeholder-tile);
  flex: 0 1 auto;
  height: 17px;
  margin-bottom: 2px;
  width: 200px;
}
.section.placeholder .sectionSlider > .sectionItem > .textContainer > .text.secondLineInfo {
  width: 140px;
}
.section.placeholder.gemtv.medium .sectionItem {
  width: var(--tile-landscape-image-width-u);
}
.section.placeholder.gemtv.medium .sectionItem > .tileContainer {
  height: var(--tile-landscape-image-height-u);
}
.section.placeholder.gemtv.big > .sectionItem {
  width: var(--tile-landscape-big-image-width-u);
}
.section.placeholder.gemtv.big > .sectionItem > .tileContainer {
  height: var(--tile-landscape-big-image-height-u);
}
.section.placeholder.channelgroup .sectionSlider .sectionItem {
  margin-block: var(--selection-thickness-u);
  margin-right: var(--tile-channel-group-horizontal-space-u);
  width: var(--tile-channel-group-image-width-u);
}
.section.placeholder.channelgroup .sectionSlider .sectionItem.placeholder > .tileContainer {
  height: var(--tile-channel-group-image-height-u);
}
.section.placeholder.channelgroup .sectionSlider .sectionItem.placeholder > .textContainer {
  display: none;
}
.section.placeholder.channelgroup.big .sectionSlider .sectionItem {
  margin-right: var(--tile-channel-group-big-horizontal-space-u);
  width: var(--tile-channel-group-big-image-width-u);
}
.section.placeholder.channelgroup.big .sectionSlider .sectionItem.placeholder > .tileContainer {
  height: var(--tile-channel-group-big-image-height-u);
  width: var(--tile-channel-group-big-image-width-u);
}
.section.placeholder.portrait .sectionSlider > .sectionItem {
  margin-right: var(--tile-portrait-horizontal-space-u);
  width: var(--tile-portrait-image-width-u);
}
.section.placeholder.portrait .sectionSlider > .sectionItem > .tileContainer {
  background-size: 100% 100%;
  height: var(--tile-portrait-image-height-u);
}
.section.placeholder.portrait .sectionSlider > .sectionItem > .textContainer {
  display: none;
}
.section.placeholder.deeplink .sectionSlider > .sectionItem {
  margin-right: var(--tile-deeplink-horizontal-space-u);
  width: var(--tile-deeplink-image-width-u);
}
.section.placeholder.deeplink .sectionSlider > .sectionItem > .tileContainer {
  background-size: 100% 100%;
  height: var(--tile-deeplink-image-height-u);
}
.section.placeholder.deeplink .sectionSlider > .sectionItem > .textContainer {
  display: none;
}
.section.placeholder.deeplink5x1 .sectionSlider > .sectionItem {
  margin-block: var(--selection-thickness-u);
  margin-right: var(--tile-deeplink-5x1-horizontal-space-u);
  width: var(--tile-deeplink-5x1-image-width-u);
}
.section.placeholder.deeplink5x1 .sectionSlider > .sectionItem > .tileContainer {
  height: var(--tile-deeplink-5x1-image-height-u);
}
.section.placeholder.deeplink3x1 .sectionSlider > .sectionItem {
  margin-block: var(--selection-thickness-u);
  margin-right: var(--tile-deeplink-3x1-horizontal-space-u);
  width: var(--tile-deeplink-3x1-image-width-u);
}
.section.placeholder.deeplink3x1 .sectionSlider > .sectionItem > .tileContainer {
  height: var(--tile-deeplink-3x1-image-height-u);
}
.section.placeholder.landscape .sectionSlider > .sectionItem {
  margin-right: var(--tile-landscape-horizontal-space-u);
  width: var(--tile-landscape-image-width-u);
}
.section.placeholder.landscape .sectionSlider > .sectionItem > .tileContainer {
  background-size: 100% 100%;
  height: var(--tile-landscape-image-height-u);
}
.section.placeholder.landscape .sectionSlider > .sectionItem > .textContainer {
  display: none;
}
.section.placeholder.landscape.big .sectionSlider > .sectionItem {
  margin-block: var(--selection-thickness-u);
  margin-right: var(--tile-landscape-big-horizontal-space-u);
  width: var(--tile-landscape-big-image-width-u);
}
.section.placeholder.landscape.big .sectionSlider > .sectionItem > .tileContainer {
  height: var(--tile-landscape-big-image-height-u);
}
.section.placeholder.landscape.vod .sectionSlider > .sectionItem {
  margin-right: var(--tile-landscape-vod-horizontal-space-u);
  width: var(--tile-landscape-vod-image-width-u);
}
.section.placeholder.landscape.vod .sectionSlider > .sectionItem > .tileContainer {
  background-size: 100% 100%;
  height: var(--tile-landscape-vod-image-height-u);
}
.section.placeholder.landscape.vod .sectionSlider > .sectionItem > .textContainer {
  display: none;
}
