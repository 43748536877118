.wrapper {
  /*
   * When scrolling, the text overflow left and right
   */
  overflow: hidden;
  white-space: nowrap;
}

.elementMeasure {
  left: -1000px;
  position: absolute;
  top: -1000px;
}
