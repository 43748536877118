@keyframes footerSlideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes footerSlideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
.footer {
  background-color: var(--footer-background);
  color: var(--footer-foreground);
  display: flex;
  flex-direction: column;
  font: 14px var(--regular-font);
  margin-top: auto;
  opacity: 0;
  position: relative;
  transform: translateY(100%);
}
.footer.slideUp {
  animation: footerSlideUp var(--short-duration) ease-out forwards;
}
.footer.slideDown {
  animation: footerSlideDown var(--short-duration) ease-in forwards;
}
.footer > .footerCommon {
  justify-content: flex-end;
}
.footer > .footerCommon > .pictoElement.topButton {
  border-radius: var(--border-radius-tiny-u);
  cursor: pointer;
  margin: 20px 20px 10px;
  transition: background-color 700ms ease-out;
}
.footer > .footerCommon > .pictoElement.topButton > svg {
  opacity: 0.6;
  width: 16px;
}
.footer > .footerCommon > .pictoElement.topButton:hover {
  background-color: var(--picto-foreground);
  opacity: 1;
}
.footer > .footerCommon > .pictoElement.topButton:hover > svg {
  fill: var(--footer-background);
  opacity: 1;
}
