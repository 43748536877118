.pictoElement {
  align-items: center;
  aspect-ratio: 1;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  display: flex;
  justify-content: center;
  overflow: visible;
  position: relative;
  width: 28px;
}
.pictoElement > svg {
  fill: var(--picto-foreground);
  opacity: 1;
  pointer-events: all;
  transition: var(--opacity-short), transform var(--short-duration) ease-out;
  width: 24px;
}
.pictoElement > .background {
  aspect-ratio: 1;
  background-color: var(--picto-background);
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 var(--shadow);
  left: 2px;
  position: absolute;
  top: 2px;
  width: 24px;
  z-index: 0;
}
.pictoElement > .background + svg {
  width: 18px;
  z-index: 1;
}
.pictoElement.recording > svg, .pictoElement.scheduledRecording > svg {
  fill: var(--recording);
}
.pictoElement.recording.live, .pictoElement.scheduledRecording.live {
  animation: pulse var(--long-duration) infinite ease-in-out;
}
.pictoElement.future svg {
  fill: var(--future);
}
.pictoElement.preview svg {
  fill: var(--catchup);
}
.pictoElement.hoverEffect > svg {
  opacity: 0.4;
  transform: scale(0.9);
}
.pictoElement.hoverEffect:not(:disabled, [disabled]) > svg {
  cursor: pointer;
  opacity: 0.9;
}
.pictoElement.hoverEffect:not(:disabled, [disabled]):hover > svg {
  opacity: 1;
  transform: scale(1);
}
