@import 'fonts.css';
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html {
  background-color: var(--background);
  height: 100vh;
  width: 100vw;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */
}
body * {
  -webkit-touch-callout: none;
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-flow: row nowrap;
  font: inherit;
  outline: 0;
  overflow: hidden;
  transition-duration: 0s;
  transition-property: none;
  user-select: none;
}
body #didomi-host * {
  display: initial;
  flex: initial;
  overflow: initial;
}
body #didomi-host a:not(.didomi-no-link-style) {
  font-weight: bold;
}
body #didomi-host .didomi-popup-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}
body #didomi-host .didomi-exterior-border,
body #didomi-host .didomi-popup-container {
  border: 0;
}
body #didomi-host .didomi-popup-notice .didomi-popup-notice-text {
  max-width: revert;
  text-align: justify;
}
body #didomi-host .didomi-consent-popup-body__explanation {
  text-align: justify;
}
body style {
  display: none;
}
body img {
  border: 0;
}
body > script,
body > iframe {
  display: none;
}
body > #root {
  align-items: stretch;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
