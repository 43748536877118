@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.infiniteCircleLoader {
  align-items: center;
  animation: rotate 2s linear infinite;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  width: 30px;
}
.infiniteCircleLoader > svg {
  width: 100%;
}
.infiniteCircleLoader.dark > svg {
  fill: var(--background);
}
.infiniteCircleLoader.light > svg {
  fill: var(--text-foreground-1);
}
