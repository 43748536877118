.equalizer {
  --equalizer-bar-start: 0;
  --equalizer-bar-width-u: 2px;
  --equalizer-bar-width-incl-margin-u: 4px;
  cursor: pointer;
  height: 20px;
  margin: 0 20px 3px;
  position: relative;
  width: 18px;
}
.equalizer.notAllowed {
  cursor: not-allowed;
}
.equalizer > span {
  background-color: var(--carousel-foreground);
  bottom: 1px;
  display: block;
  height: 100%;
  margin: 0;
  position: absolute;
  transform: scaleY(1);
  transform-origin: center bottom;
  transition: transform var(--short-duration) ease-out;
  width: var(--equalizer-bar-width-u);
}
.equalizer > span:nth-child(1) {
  left: 0px;
}
.equalizer > span:nth-child(2) {
  left: 4px;
}
.equalizer > span:nth-child(3) {
  left: 8px;
}
.equalizer > span:nth-child(4) {
  left: 12px;
}
.equalizer > span:nth-child(5) {
  left: 16px;
}
.equalizer .muteSign {
  left: var(--equalizer-bar-start);
  pointer-events: none;
  position: absolute;
}
.equalizer .muteSign > svg > line {
  stroke: var(--background);
  stroke-width: 2px;
}
