.mainLayout {
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
}

.alertPicto {
  height: 24px;
  width: 24px;
}
.alertPicto.failure svg {
  fill: #b90015;
}
.alertPicto.failure svg path:last-child {
  fill: var(--background);
}
.alertPicto.warning svg {
  fill: var(--warning);
}
.alertPicto.warning svg path:last-child {
  fill: #000;
}

::-webkit-scrollbar {
  -webkit-box-shadow: unset;
  background: var(--scrollbar-background);
  height: 14px;
  width: 14px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: unset;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: unset;
  background: var(--scrollbar-thumb);
}

::-webkit-scrollbar-corner {
  -webkit-box-shadow: unset;
  background: transparent;
}
