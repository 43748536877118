.playerVolumeContainer {
  align-items: center;
  display: flex;
  position: relative;
}
.playerVolumeContainer > .pictoVolumeDown,
.playerVolumeContainer > .pictoVolumeUp,
.playerVolumeContainer > .pictoVolumeMuted {
  position: absolute;
}
.playerVolumeContainer > .volumeController {
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
  width: 80px;
}
.playerVolumeContainer > .volumeController > .volumeBar {
  background-color: var(--player-controller-foreground-disabled);
  height: 22px;
  opacity: 0.9;
  transform: scale(0.9);
  transition: var(--opacity-short), transform var(--short-duration) ease-out;
  width: 8px;
}
.playerVolumeContainer > .volumeController > .volumeBar.active {
  background-color: var(--player-controller-foreground);
}
.playerVolumeContainer > .volumeController > .volumeBar:hover {
  opacity: 1;
  transform: scale(1);
}
