.episode.tv > .header > .clock {
  height: 100%;
  opacity: 0.1;
  position: absolute;
  right: 190px;
}
.episode.tv > .header > .clock > svg {
  aspect-ratio: 1;
  fill: var(--tile-background-clock);
  width: 150px;
}
.episode.tv > .header > .dateAndTitle {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  margin-right: auto;
  white-space: nowrap;
}
.episode.tv > .header > .dateAndTitle > .date {
  font: 16px var(--bold-font);
}
.episode.tv > .header > .dateAndTitle > .title {
  font: 16px var(--regular-font);
}
.episode.tv > .header > .actions > .recording {
  width: 38px;
}
.episode.tv > .header > .actions > .recording > svg {
  width: 100%;
}
.episode.tv > .header > .actions > .recording > svg > circle {
  r: 34;
}
.episode.tv > .content > .cover {
  height: 160px;
  width: 284px;
}
.episode.tv > .content > .details > .programInfo > .statusPicto {
  margin-right: 12px;
}
.episode.tv > .content > .details > .programInfo > .liveRecording {
  margin-left: -10px;
}
.episode.tv > .content > .details > .programInfo > .liveRecording svg {
  width: 10px;
}
.episode.tv > .content > .details > .programInfo > .recordError {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.episode.tv > .content > .details > .programInfo > .recordError > .pictoElement > svg {
  width: 20px;
}
.episode.tv > .content > .details > .programInfo > .recordError > .description {
  align-items: center;
  font: 16px var(--regular-font);
  opacity: 0.8;
  text-decoration: underline;
  transition: var(--opacity-short);
}
.episode.tv > .content > .details > .programInfo > .recordError:hover > .description {
  opacity: 1;
}
.episode.tv > .content > .details > .programInfo > .duration > .real {
  color: var(--recording);
}
.episode.tv > .content > .details > .programInfo > .duration > .separator {
  margin: 0 5px;
}
