.modalDialogDiv.authenticationRequired {
  max-width: 500px;
}
.modalDialogDiv.authenticationRequired > .content > .header {
  padding: 50px var(--modal-inline-padding-u) 0 var(--modal-inline-padding-u);
}
.modalDialogDiv.authenticationRequired > .content > .authenticationRequiredContent {
  align-items: center;
  color: var(--overlay-foreground-1);
  display: flex;
  flex-direction: column;
  font: 16px var(--regular-font);
  padding: 0 var(--modal-inline-padding-u) 30px var(--modal-inline-padding-u);
}
.modalDialogDiv.authenticationRequired > .content > .authenticationRequiredContent > div:first-child {
  margin-block: 30px;
}
