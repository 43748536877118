.section.channelGroupSection {
  --channel-group-navigation-button-size-u: 80px;
  --channel-group-navigation-button-collapsed-position-u: calc(var(--channel-group-navigation-button-size-u) / 4);
  --channel-group-navigation-button-expanded-position-u: calc(var(--channel-group-navigation-button-size-u) / 8);
  --channel-group-navigation-button-picto-margin-u: calc(var(--channel-group-navigation-button-collapsed-position-u) / 2);
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  pointer-events: auto;
}
.section.channelGroupSection > div {
  overflow: visible;
}
.section.channelGroupSection > .navigationButton {
  align-items: center;
  background-color: var(--section-navigation-buttons);
  cursor: pointer;
  display: flex;
  height: var(--channel-group-navigation-button-size-u);
  justify-content: center;
  position: absolute;
  top: calc((125px - var(--channel-group-navigation-button-size-u)) / 2 + 38px);
  transition: left var(--short-duration) ease-out, right var(--short-duration) ease-out;
  width: var(--channel-group-navigation-button-size-u);
}
.section.channelGroupSection > .navigationButton:hover {
  background-color: var(--section-navigation-buttons-hover);
}
.section.channelGroupSection > .navigationButton > .pictoElement {
  height: 20px;
}
.section.channelGroupSection > .navigationButton.previous {
  border-radius: 0 50% 50% 0;
  left: calc(-1 * var(--channel-group-navigation-button-collapsed-position-u));
}
.section.channelGroupSection > .navigationButton.previous:hover {
  left: calc(-1 * var(--channel-group-navigation-button-expanded-position-u));
}
.section.channelGroupSection > .navigationButton.previous > .pictoElement {
  margin-left: var(--channel-group-navigation-button-picto-margin-u);
}
.section.channelGroupSection > .navigationButton.next {
  border-radius: 50% 0 0 50%;
  right: calc(-1 * var(--channel-group-navigation-button-collapsed-position-u));
}
.section.channelGroupSection > .navigationButton.next:hover {
  right: calc(-1 * var(--channel-group-navigation-button-expanded-position-u));
}
.section.channelGroupSection > .navigationButton.next > .pictoElement {
  margin-left: calc(-1 * var(--channel-group-navigation-button-picto-margin-u));
}
.section.channelGroupSection > .header {
  align-items: center;
  display: flex;
  margin: 0 0 8px 13px;
}
.section.channelGroupSection > .header .sectionTitleContainer {
  align-items: center;
  color: var(--text-foreground-1);
  display: flex;
  font: 18px var(--bold-font);
  user-select: none;
}
.section.channelGroupSection .slider {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: var(--tile-channel-group-image-height-u);
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: transform var(--short-duration) ease-out;
}
