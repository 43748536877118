.videoCarousel {
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.videoCarousel > video {
  background-color: var(--player-background);
  left: 0;
  pointer-events: auto;
  position: absolute;
  transform: translateY(-140px);
  transition: transform var(--medium-duration) ease-in-out;
  width: 100%;
  z-index: -100;
}
.videoCarousel > .mask {
  background-color: var(--background);
  height: 700px;
  position: absolute;
  top: 600px;
  transform: translateY(0);
  transition: transform var(--long-duration) ease-out;
  width: 100%;
  z-index: -50;
}
.videoCarousel > .mask.reduced {
  transform: translateY(180px);
}
.videoCarousel > .backBar {
  align-items: flex-end;
  cursor: pointer;
  display: none;
  height: 40px;
  margin: 50px 0 0 50px;
  pointer-events: auto;
}
.videoCarousel > .backBar > .pictoElement {
  background-color: var(--player-controller-foreground);
  border-radius: 50%;
  width: 36px;
}
.videoCarousel > .backBar > .pictoElement > svg {
  fill: var(--player-overlay-foreground-1);
  opacity: 0.7;
  width: 16px;
}
.videoCarousel.fullscreen > .backBar {
  display: flex;
}
.videoCarousel.fullscreen > video {
  transform: translateY(0);
}
