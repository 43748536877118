.sortAndFilter {
  align-items: center;
  display: flex;
  margin-left: 20px;
}
.sortAndFilter > input {
  background-color: transparent;
  border: 2px solid var(--input-border);
  border-radius: var(--border-radius-huge-u);
  color: var(--search-grid-foreground);
  display: flex;
  font: 16px var(--regular-font);
  justify-content: center;
  margin-right: 20px;
  padding: 8px 12px;
  user-select: text;
  width: 250px;
}
.sortAndFilter > input::placeholder {
  color: var(--search-grid-placeholder);
}
.sortAndFilter > button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--placeholder-input);
  cursor: pointer;
}
.sortAndFilter > button.selected {
  color: var(--text-foreground-1);
}
.sortAndFilter > button > .pictoElement > svg {
  fill: var(--text-foreground-1);
  width: 20px;
}
