.channelSlider > .channel {
  align-items: center;
  background: no-repeat center center;
  background-size: contain;
  cursor: pointer;
  display: flex;
  height: var(--channel-list-item-height-u);
  justify-content: center;
  position: relative;
  width: var(--channel-list-item-width-u);
}
.channelSlider > .channel.disabled {
  cursor: default;
}
.channelSlider > .channel:hover:not(.disabled) {
  background-color: var(--player-channel-zapper-background-hover);
}
.channelSlider > .channel > .channelImage {
  background-repeat: no-repeat;
  background-size: contain;
  height: var(--channel-image-height-u);
  width: var(--channel-image-width-u);
}
.channelSlider > .channel > .channelName {
  align-items: center;
  color: var(--player-controller-foreground);
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
}

.channelZapper.open > .channelList > .channelContainer > .channelSlider {
  margin-bottom: 50px;
}
.channelZapper.open > .channelList > .channelContainer > .channelSlider > .channel {
  border-bottom: 1px solid var(--separator-light);
}
