.npvrHelp {
  max-height: 650px;
  width: 60%;
}
.npvrHelp > .content div,
.npvrHelp > .content span,
.npvrHelp > .content ul,
.npvrHelp > .content li {
  color: var(--overlay-foreground-1);
  font: 16px var(--regular-font);
}
.npvrHelp > .content > .helpContent {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px var(--modal-inline-padding-u) 30px;
}
.npvrHelp > .content > .helpContent > .sectionTitle {
  font: 18px var(--semibold-font);
  margin: 30px 0 15px;
}
.npvrHelp > .content > .helpContent > ul {
  display: flex;
  flex-direction: column;
  list-style: square inside none;
  margin: 0;
  padding: 0;
}
.npvrHelp > .content > .helpContent > ul.noBullet {
  list-style: none;
}
.npvrHelp > .content > .helpContent > ul > li {
  display: list-item;
  margin-bottom: 10px;
  text-align: left;
}
.npvrHelp > .content > .helpContent > ul > li > div {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.npvrHelp > .content > .helpContent > ul > li > div > .buttonFX > .disabled {
  background-color: transparent;
}
.npvrHelp > .content > .helpContent > ul > li > div > .hint {
  all: unset;
  font: 16px var(--regular-font);
  margin-left: 10px;
}
