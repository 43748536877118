.mainLayout > .header > .headerMiddle > .menuWrapper {
  align-items: center;
  flex: 1 1 auto;
  margin-top: 7px;
  min-width: var(--burger-width-u);
  overflow: visible;
  pointer-events: auto;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon {
  align-items: center;
  color: var(--header-foreground);
  cursor: pointer;
  font: 15px var(--regular-font);
  height: 40px;
  margin: 0 auto 0 0;
  opacity: 0.8;
  overflow: visible;
  padding: 5px;
  position: relative;
  transition: var(--opacity-short), transform var(--short-duration) ease-in-out;
  user-select: none;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .pictoElement {
  width: 20px;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .pictoElement > svg {
  width: 14px;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu {
  background-color: var(--header-burger-menu-background);
  border-radius: var(--border-radius-big-u);
  display: flex;
  flex-direction: column;
  min-width: 220px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 40px;
  transform: scaleY(0);
  transform-origin: center 0;
  transition: transform var(--short-duration) ease-out, var(--opacity-short);
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu > .avenueElement {
  padding: 10px 20px;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu > .avenueElement:not(:first-child) {
  border-top: 1px solid var(--header-burger-menu-separator);
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu > .avenueElement > .text {
  color: var(--header-burger-menu-foreground);
  display: flex;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu > .avenueElement:hover {
  background-color: var(--header-burger-menu-background-hover);
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu > .avenueElement:hover > .text {
  opacity: 1;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon > .burgerMenu > .avenueElement::before {
  display: none;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon:hover {
  opacity: 1;
}
.mainLayout > .header > .headerMiddle > .menuWrapper > .burgerIcon:hover > .burgerMenu {
  opacity: 0.9;
  transform: scaleY(1);
}
