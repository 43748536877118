.searchBox {
  align-items: center;
  display: flex;
  flex-direction: row;
  pointer-events: auto;
}
.searchBox > .pictoElement {
  height: 44px;
  width: 44px;
}
.searchBox > input {
  background-color: transparent;
  border: 2px solid var(--input-border);
  border-radius: var(--border-radius-huge-u);
  color: var(--search-header-foreground);
  font: 15px var(--regular-font);
  justify-content: center;
  margin-right: 12px;
  padding: 8px 12px;
  user-select: text;
  width: 280px;
}
.searchBox > input::-moz-selection {
  background: var(--search-header-selection-background);
  color: var(--search-header-selection-foreground);
}
.searchBox > input::selection {
  background: var(--search-header-selection-background);
  color: var(--search-header-selection-foreground);
}
.searchBox > input::placeholder {
  color: var(--placeholder-input);
}
