@keyframes debugElasticAppearing {
  0% {
    opacity: 0;
    transform: scale(0);
    width: 0;
  }
  1% {
    opacity: 0;
    transform: scale(0);
    width: auto;
  }
  66% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes debugElasticDisappearing {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  33% {
    opacity: 1;
    transform: scale(1.3);
  }
  99% {
    opacity: 0;
    transform: scale(0);
    width: auto;
  }
  100% {
    opacity: 0;
    transform: scale(0);
    width: 0;
  }
}
.debugPicto {
  height: 20px;
  margin-left: 5px;
  width: 20px;
}
.debugPicto.dark {
  fill: var(--text-foreground-1);
}
.debugPicto.light {
  fill: var(--overlay-background);
}
.debugPicto.hidden {
  display: none;
  opacity: 0;
  transform: scale(0);
}
.debugPicto.visible {
  display: block;
  opacity: 1;
  transform: scale(1);
}
.debugPicto.appearing {
  animation: debugElasticAppearing var(--medium-duration) ease-out forwards;
}
.debugPicto.disappearing {
  animation: debugElasticDisappearing var(--medium-duration) ease-out forwards;
}
