@keyframes movingArrow {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    transform: translateX(5px);
  }
  100% {
    opacity: 1;
    transform: translateX(10px);
  }
}
.section {
  --section-header-height-u: 27px;
  --section-header-bottom-margin-u: 8px;
  --navigation-button-size-u: 126px;
  --navigation-button-collapsed-position-u: calc(var(--navigation-button-size-u) / -4);
  --navigation-button-expanded-position-u: calc(var(--navigation-button-size-u) / -8);
  --navigation-button-picto-margin-u: calc(var(--navigation-button-size-u) / 8);
  --navigation-button-vertical-shift-u: calc(var(--section-header-height-u) + var(--section-header-bottom-margin-u));
  --navigation-button-vertical-shift-selection-u: calc(var(--navigation-button-vertical-shift-u) + var(--selection-thickness-u));
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  opacity: 1;
  overflow: visible;
  padding-left: 50px;
  pointer-events: auto;
  position: relative;
  transform: scaleY(1) translateY(0);
  transform-origin: top;
  transition: opacity ease-out, transform ease-out;
  width: 100%;
}
.section.hidden {
  opacity: 0;
  transform: translateY(300px);
  transition-duration: var(--long-duration);
}
.section > .backBar {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  margin-bottom: 20px;
  margin-right: auto;
}
.section > div {
  overflow: visible;
}
.section > .navigationButton {
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--section-navigation-buttons);
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: left var(--short-duration) ease-out, right var(--short-duration) ease-out, var(--opacity-short);
  width: var(--navigation-button-size-u);
  z-index: 250;
}
.section > .navigationButton:hover {
  background-color: var(--section-navigation-buttons-hover);
}
.section > .navigationButton > .pictoElement {
  height: 20px;
}
.section > .navigationButton.previous {
  border-radius: 0 50% 50% 0;
  left: var(--navigation-button-collapsed-position-u);
}
.section > .navigationButton.previous:hover {
  left: var(--navigation-button-expanded-position-u);
}
.section > .navigationButton.previous > .pictoElement {
  margin-left: var(--navigation-button-picto-margin-u);
}
.section > .navigationButton.next {
  border-radius: 50% 0 0 50%;
  margin-left: auto;
  right: var(--navigation-button-collapsed-position-u);
}
.section > .navigationButton.next:hover {
  right: var(--navigation-button-expanded-position-u);
}
.section > .navigationButton.next > .pictoElement {
  margin-left: var(--navigation-button-picto-margin-u);
}
.section > .navigationButton.gemtv {
  top: calc((var(--tile-gemtv-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-u));
}
.section > .navigationButton.portrait, .section > .navigationButton.deeplink {
  top: calc((var(--tile-portrait-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-u));
}
.section > .navigationButton.deeplink3x1, .section > .navigationButton.deeplink5x1 {
  top: calc((var(--tile-deeplink-3x1-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-selection-u));
}
.section > .navigationButton.landscape {
  top: calc((var(--tile-landscape-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-u));
}
.section > .navigationButton.landscape.big {
  top: calc((var(--tile-landscape-big-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-selection-u));
}
.section > .navigationButton.landscape.vod {
  top: calc((var(--tile-landscape-vod-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-u));
}
.section > .navigationButton.channelgroup {
  top: calc((var(--tile-channel-group-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-selection-u));
}
.section > .navigationButton.channelgroup.big {
  top: calc((var(--tile-channel-group-big-image-height-u) - var(--navigation-button-size-u)) / 2 + var(--navigation-button-vertical-shift-selection-u));
}
.section:hover > .navigationButton {
  opacity: 1;
}
.section:hover > .header > .gridViewButton {
  opacity: 0.8;
}
.section:hover > .header > .sectionTitleContainer > .sectionTitle {
  opacity: 1;
  transform: scale(1.01);
}
.section.grid {
  height: auto;
  margin-top: 0;
}
.section.grid > .header {
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  margin-bottom: 20px;
  row-gap: 10px;
}
.section.grid > .header > .sectionTitleContainer .sectionTitle {
  font-size: 36px;
  opacity: 1;
  transform: none;
  transition: none;
}
.section.grid > .header > .sortAndFilter {
  margin: 0;
}
.section.grid .sectionSlider {
  flex: 1 1;
  flex-flow: row wrap;
  height: auto;
  justify-content: flex-start;
  margin: 0;
  row-gap: 60px;
}
.section.grid .sectionSlider::after {
  content: "";
  flex: auto;
}
.section.grid .sectionSlider > .sectionItem {
  margin-bottom: 10px;
  margin-top: 10px;
}
.section > .header {
  align-items: center;
  display: flex;
  height: var(--section-header-height-u);
  margin-bottom: var(--section-header-bottom-margin-u);
  padding-right: 20px;
}
.section > .header > .sectionTitleContainer {
  align-items: center;
  display: flex;
  overflow: visible;
}
.section > .header > .sectionTitleContainer.hoverable {
  cursor: pointer;
}
.section > .header > .sectionTitleContainer > .sectionTitle {
  color: var(--text-foreground-1);
  font: 20px var(--bold-font);
  margin-left: 5px;
  opacity: 0.7;
  transform: scale(1);
  transform-origin: left;
  transition: transform var(--short-duration) ease-out;
  user-select: none;
}
.section > .header > .gridViewButton {
  align-items: center;
  color: var(--text-foreground-1);
  cursor: pointer;
  display: flex;
  font: 16px var(--regular-font);
  margin-left: 30px;
  opacity: 0;
}
.section > .header > .gridViewButton:hover {
  opacity: 1;
  text-decoration: underline;
}
.section > .header > .gridViewButton:hover > .pictoElement {
  animation: movingArrow var(--short-duration) ease-out forwards;
  margin-inline: -10px 10px;
}
.section > .header > .gridViewButton > .pictoElement {
  height: 24px;
  opacity: 0;
}
.section > .header > .gridViewButton > .pictoElement > svg {
  fill: var(--text-foreground-1);
  width: 14px;
}
.section > .header > .sectionPagination {
  color: var(--text-foreground-1);
  font: 14px var(--semibold-font);
  margin-left: auto;
  opacity: 0.8;
}
.section.placeholder > .header .sectionTitleContainer.noTitle {
  background-color: var(--placeholder-text);
  height: 17px;
  width: 250px;
}
.section .sectionSlider {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: transform var(--short-duration) ease-out;
}
