@font-face {
  font-display: swap;
  font-family: OpenSansLight;
  src: url("./assets/fonts/openSans/OpenSans-Light.eot");
  src: url("./assets/fonts/openSans/OpenSans-Light.woff2") format("woff2"), url("./assets/fonts/openSans/OpenSans-Light.woff") format("woff"), url("./assets/fonts/openSans/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: OpenSansRegular;
  src: url("./assets/fonts/openSans/OpenSans-Regular.eot");
  src: url("./assets/fonts/openSans/OpenSans-Regular.woff2") format("woff2"), url("./assets/fonts/openSans/OpenSans-Regular.woff") format("woff"), url("./assets/fonts/openSans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: OpenSansSemiBold;
  src: url("./assets/fonts/openSans/OpenSans-SemiBold.eot");
  src: url("./assets/fonts/openSans/OpenSans-SemiBold.woff2") format("woff2"), url("./assets/fonts/openSans/OpenSans-SemiBold.woff") format("woff"), url("./assets/fonts/openSans/OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: OpenSansBold;
  src: url("./assets/fonts/openSans/OpenSans-Bold.eot");
  src: url("./assets/fonts/openSans/OpenSans-Bold.woff2") format("woff2"), url("./assets/fonts/openSans/OpenSans-Bold.woff") format("woff"), url("./assets/fonts/openSans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: OpenSansBlack;
  src: url("./assets/fonts/openSans/OpenSans-ExtraBold.eot");
  src: url("./assets/fonts/openSans/OpenSans-ExtraBold.woff2") format("woff2"), url("./assets/fonts/openSans/OpenSans-ExtraBold.woff") format("woff"), url("./assets/fonts/openSans/OpenSans-ExtraBold.ttf") format("truetype");
}
