.videoController {
  align-items: stretch;
  background-color: var(--player-controller-background);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  overflow: visible;
  padding: 0 40px;
  pointer-events: none;
  position: relative;
  transition: var(--opacity-short);
  z-index: 550;
}
.videoController:has(.channelZapper) {
  padding-left: 0;
}
.videoController.visible {
  opacity: 1;
  pointer-events: auto;
}
.videoController > .controller {
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: visible;
  position: relative;
}
.videoController > .controller > .textInfo {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
}
.videoController > .controller > .textInfo > .titlesAndStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 0 0;
}
.videoController > .controller > .textInfo > .titlesAndStatus.multiline {
  justify-content: flex-start;
}
.videoController > .controller > .textInfo > .titlesAndStatus .title {
  color: var(--player-controller-foreground);
  font: 20px var(--regular-font);
  user-select: none;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine {
  align-items: center;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine > .title {
  font: 24px var(--semibold-font);
  line-height: 30px;
  user-select: none;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine > .statusPicto {
  margin: 0 20px;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine > .statusPicto.live > .recording {
  margin-top: 2px;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine > .statusPicto.notLive {
  background-color: var(--timeshift);
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine > .liveRecording {
  align-items: center;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine > .liveRecording > .text {
  color: var(--player-controller-foreground);
  margin-left: 5px;
}
.videoController > .controller > .textInfo > .titlesAndStatus > .mainLine + .title {
  margin-top: 5px;
}
.videoController > .controller > .textInfo > .channelImage {
  height: var(--channel-image-height-u);
  justify-content: center;
  margin-left: -5px;
  margin-right: 3px;
  overflow: visible;
  padding-top: 5px;
  width: var(--channel-image-width-u);
}
.videoController > .controller > .textInfo > .channelImage > img {
  object-fit: scale-down;
  user-select: none;
}
.videoController > .controller > .textInfo > .channelImage + .titlesAndStatus {
  margin-left: 10px;
}
.videoController > .controller > .textInfo > .videoQualityAndTime {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .videoQuality {
  align-items: center;
  color: var(--player-controller-foreground);
  font: 14px var(--semibold-font);
  margin-bottom: 5px;
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .videoQuality > div {
  border: 1px solid var(--player-controller-foreground);
  border-radius: var(--border-radius-tiny-u);
  margin-left: 10px;
  padding: 2px 4px;
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .videoQuality > .leaf {
  height: 25px;
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .timeInfo > div {
  align-items: flex-end;
  color: var(--player-controller-foreground);
  font: 20px var(--regular-font);
  user-select: none;
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .timeInfo > div.first {
  color: var(--player-controller-foreground);
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .timeInfo > div.first.margin {
  color: var(--player-time-in-margin);
}
.videoController > .controller > .textInfo > .videoQualityAndTime > .timeInfo > div.timeSeparator {
  margin: 0 5px;
}
.videoController > .controller > .seekBar {
  align-items: center;
  height: 40px;
  justify-content: center;
}
.videoController > .controller > .buttons {
  align-items: center;
  flex-wrap: wrap;
  height: 50px;
  justify-content: space-between;
  overflow: visible;
}
.videoController > .controller > .buttons > .controlButtonContainer,
.videoController > .controller > .buttons > .infoButtonContainer {
  align-items: center;
}
.videoController > .controller > .buttons > .controlButtonContainer .pictoElement :not(:disabled) svg,
.videoController > .controller > .buttons > .infoButtonContainer .pictoElement :not(:disabled) svg {
  fill: var(--player-controller-foreground);
}
.videoController > .controller > .buttons > .controlButtonContainer .pictoElement:not(.skipPicto),
.videoController > .controller > .buttons > .infoButtonContainer .pictoElement:not(.skipPicto) {
  justify-content: center;
  width: 50px;
}
.videoController > .controller > .buttons > .controlButtonContainer .pictoElement:not(.skipPicto) > svg,
.videoController > .controller > .buttons > .infoButtonContainer .pictoElement:not(.skipPicto) > svg {
  width: 18px;
}
.videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer,
.videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer {
  height: 50px;
}
.videoController > .controller > .buttons > .controlButtonContainer {
  justify-content: flex-start;
}
.videoController > .controller > .buttons > .infoButtonContainer {
  justify-content: flex-end;
}
.videoController .channelZapper + .controller {
  margin-left: 20px;
}

@media screen and (width <= 1100px) {
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement {
    margin-right: 20px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement.prev {
    margin-left: 20px;
  }
  .videoController > .controller > .buttons > .infoButtonContainer > .pictoElement {
    margin-left: 20px;
  }
}
@media screen and (width <= 950px) {
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement {
    margin-right: 5px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement.prev {
    margin-left: 5px;
  }
  .videoController > .controller > .buttons > .infoButtonContainer > .pictoElement {
    margin-left: 5px;
  }
}
@media screen and (width <= 820px) {
  .videoController > .controller > .buttons > .controlButtonContainer,
  .videoController > .controller > .buttons > .infoButtonContainer {
    height: 50px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement,
  .videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer > .pictoElement,
  .videoController > .controller > .buttons > .infoButtonContainer > .pictoElement,
  .videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer > .pictoElement {
    width: 40px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement > svg,
  .videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer > .pictoElement > svg,
  .videoController > .controller > .buttons > .infoButtonContainer > .pictoElement > svg,
  .videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer > .pictoElement > svg {
    width: 25px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer,
  .videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer {
    height: 40px;
  }
}
@media screen and (width <= 725px) {
  .videoController > .controller > .buttons > .controlButtonContainer,
  .videoController > .controller > .buttons > .infoButtonContainer {
    height: 45px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement,
  .videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer > .pictoElement,
  .videoController > .controller > .buttons > .infoButtonContainer > .pictoElement,
  .videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer > .pictoElement {
    width: 35px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .pictoElement > svg,
  .videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer > .pictoElement > svg,
  .videoController > .controller > .buttons > .infoButtonContainer > .pictoElement > svg,
  .videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer > .pictoElement > svg {
    width: 20px;
  }
  .videoController > .controller > .buttons > .controlButtonContainer > .playerVolumeContainer,
  .videoController > .controller > .buttons > .infoButtonContainer > .playerVolumeContainer {
    height: 35px;
  }
}
