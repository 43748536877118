.avenueElement {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  font: 14px var(--semibold-font);
  height: 40px;
  justify-content: flex-start;
}
.avenueElement.separator::before {
  color: var(--header-foreground);
  content: "|";
  font: inherit;
  margin: 0 22px 0 19px;
  pointer-events: none;
}
.avenueElement > .text {
  align-items: center;
  background-color: transparent;
  color: var(--header-foreground);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font: inherit;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.7;
  padding: 5px;
  transition: var(--opacity-short);
  user-select: none;
}
.avenueElement > .text::after {
  content: attr(data-text);
  display: block;
  font-family: var(--bold-font);
  height: 0;
  visibility: hidden;
}
.avenueElement > .text:hover {
  color: var(--accent);
  opacity: 1;
}
.avenueElement.focused > .text {
  font-family: var(--bold-font);
  opacity: 1;
}
