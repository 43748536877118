.buttonFX {
  cursor: pointer;
  position: relative;
}
.buttonFX > .button {
  align-items: stretch;
  border-radius: var(--border-radius-tiny-u);
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}
.buttonFX > .button > .content {
  align-items: center;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  font: 16px var(--semibold-font);
  justify-content: center;
  pointer-events: none;
  user-select: none;
  z-index: 1;
}
.buttonFX > .button > .content.padding {
  padding-inline: 20px;
}
.buttonFX > .button > .content div,
.buttonFX > .button > .content span {
  color: inherit;
}
.buttonFX > .button.dark {
  background-color: var(--button-dark-background);
}
.buttonFX > .button.dark > .content {
  color: var(--button-dark-foreground);
}
.buttonFX > .button.dark.empty {
  background-color: transparent;
  border: 1px solid var(--button-dark-empty-border);
}
.buttonFX > .button.light {
  background-color: var(--button-light-background);
}
.buttonFX > .button.light > .content {
  color: var(--button-light-foreground);
}
.buttonFX > .button.light.empty {
  background-color: transparent;
  border: 1px solid var(--button-light-empty-border);
}
.buttonFX > .button:hover {
  border-color: transparent;
}
.buttonFX > .button:hover.empty {
  border-color: transparent;
}
.buttonFX > .button:hover > .content {
  color: var(--button-foreground-hover);
}
.buttonFX > .button:not(:hover).empty > .content {
  color: var(--text-foreground-1);
}
.buttonFX > .button > .hoverLayer {
  height: 100%;
  position: absolute;
  width: 100%;
}
.buttonFX.heightStretched {
  height: 100%;
}
.buttonFX.heightSmall {
  height: 30px;
}
.buttonFX.heightMedium {
  height: 40px;
}
.buttonFX.heightLarge {
  height: 46px;
}
.buttonFX.widthAuto {
  width: auto;
}
.buttonFX.widthStretched {
  width: 100%;
}
.buttonFX.widthSmall {
  width: 164px;
}
.buttonFX.widthLarge {
  width: 210px;
}
.buttonFX.loading > .button > .content {
  opacity: 0.4;
}
.buttonFX > .disabled {
  background-color: var(--button-background-disabled);
  border-radius: var(--border-radius-tiny-u);
  cursor: default;
  inset: 0;
  position: absolute;
}
.buttonFX > .loader {
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  left: calc((100% - 30px) / 2);
  position: absolute;
  top: calc((100% - 30px) / 2);
  width: 30px;
}
