.graphRoot {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px;

  &.red {
    --border-color: #9d0000;
    --background-color: rgb(255 0 0 / 18%);
  }

  &.green {
    --border-color: #009d00;
    --background-color: rgb(0 255 0 / 18%);
  }

  &.blue {
    --border-color: #00009d;
    --background-color: rgb(0 0 255 / 18%);
  }

  &.yellow {
    --border-color: #9d9d00;
    --background-color: rgb(255 255 0 / 18%);
  }

  > .graphTitle {
    background-color: var(--border-color);
    border-radius: 3px 3px 0 0;
    color: #fff;
    font-size: 10px;
    padding: 2px 6px;
  }

  > .graphData {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    height: 100px;
    margin-inline: auto;
    width: 240px;
  }
}
