.progressBar {
  align-items: center;
  height: 30px;
  overflow: visible;
}
.progressBar > .reactiveBackground {
  background-color: transparent;
  height: 30px;
  overflow: visible;
  padding: 10px 0;
  width: 100%;
}
.progressBar > .reactiveBackground > .mainContainer {
  background-color: var(--player-progress-bar-unavailable);
  cursor: not-allowed;
  height: 10px;
  overflow: visible;
  position: relative;
  width: 100%;
}
.progressBar > .reactiveBackground > .mainContainer > .available {
  background-color: var(--player-progress-bar-available);
  display: flex;
  height: 100%;
  overflow: visible;
  position: absolute;
  width: 0;
  z-index: 600;
}
.progressBar > .reactiveBackground > .mainContainer > .buffered {
  background-color: var(--player-progress-bar-buffered);
  display: flex;
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 0;
  z-index: 610;
}
.progressBar > .reactiveBackground > .mainContainer > .progress {
  background-color: var(--accent);
  display: flex;
  overflow: visible;
  pointer-events: none;
  position: relative;
  width: 0;
  z-index: 620;
}
.progressBar > .reactiveBackground > .mainContainer > .progress > .thumb {
  pointer-events: auto;
  position: absolute;
  right: -13px;
  top: -9px;
  z-index: 630;
}
.progressBar > .reactiveBackground > .mainContainer > .progress > .thumb > svg {
  fill: var(--accent);
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  transition: var(--opacity-medium), transform var(--short-duration) ease-out;
}
.progressBar > .reactiveBackground > .mainContainer > .progress > .thumb.dragged > svg {
  opacity: 1;
  transform: scale(1);
}
.progressBar > .reactiveBackground > .mainContainer > .timeBadge {
  background-color: var(--player-overlay-background);
  border-radius: var(--border-radius-tiny-u);
  color: var(--player-overlay-foreground-1);
  font: 18px var(--regular-font);
  left: 0;
  padding: 5px 10px;
  position: absolute;
  top: -45px;
  user-select: none;
}
.progressBar > .reactiveBackground > .mainContainer > .timeBadge.inMargin {
  color: var(--player-time-in-margin);
}
.progressBar > .reactiveBackground:hover > .mainContainer > .progress > .thumb {
  cursor: pointer;
}
.progressBar > .reactiveBackground:hover > .mainContainer > .progress > .thumb > svg {
  opacity: 1;
  transform: scale(1);
}
