.circleLoader {
  align-items: center;
  align-self: center;
  aspect-ratio: 1;
  background-color: rgba(22, 47, 73, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 50px;
  overflow: visible;
  position: relative;
  width: 80px;
}
.circleLoader > svg {
  aspect-ratio: 1;
  position: absolute;
  width: 104px;
}
.circleLoader > svg > circle {
  fill: transparent;
  stroke: var(--accent);
  stroke-dasharray: 252;
  stroke-dashoffset: 0;
  stroke-width: 4px;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 50ms linear;
}
.circleLoader > svg > circle.background {
  stroke: rgba(255, 255, 255, 0.3);
}
.circleLoader > .percentage {
  color: var(--text-foreground-1);
  font: 18px var(--semibold-font);
}
