.sectionItem {
  > .tileContainer {
    > .decoration {
      border-radius: inherit;
      height: 100%;
      opacity: 1;
      position: absolute;
      transition: var(--opacity-short);
      width: 100%;
      z-index: 310;

      &.tv {
        background: var(--tile-tv-bottom-gradient);
      }

      > * {
        position: absolute;
      }

      // Channel logo in tile's corner
      > .channelImage {
        background: no-repeat left center;
        background-size: contain;
        bottom: 10px;
        height: var(--tile-channel-logo-height-u);
        left: 10px;
        width: var(--tile-channel-logo-width-u);
      }

      > .series {
        bottom: 10px;
        right: 10px;

        > svg {
          path {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              fill: #000;
            }
          }
        }
      }

      > .future,
      > .preview,
      > .recording,
      > .scheduledRecording {
        left: 10px;
        top: 10px;
      }

      > .watchingStatus {
        margin: auto;
        overflow: visible;
        position: relative;
      }

      > .debugInfo {
        background-color: var(--dimmer);
        border: 2px solid var(--accent);
        border-radius: calc(var(--tile-selection-border-radius-u) - 4px) 0;
        color: var(--tile-hover-foreground-1);
        display: flex;
        flex-direction: column;
        padding: 5px;

        > div {
          display: flex;
          flex-direction: column;
          font: 12px var(--semibold-font);
          user-select: none;

          &.record {
            border-top: 1px solid var(--overlay-foreground-2);
            margin-top: 3px;
            padding-top: 3px;
          }
        }
      }
    }
  }
}
