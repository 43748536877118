@charset "UTF-8";
.credits {
  flex-wrap: wrap;
  font: 16px var(--regular-font);
  text-align: left;
}
.credits > span {
  display: inline-block;
  font: 16px var(--light-font);
  position: relative;
}
.credits > span.label {
  color: var(--text-foreground-2);
  font: 16px var(--regular-font);
  margin-right: 5px;
  white-space: nowrap;
}
.credits > span.cast {
  color: var(--text-foreground-1);
}
.credits > span.cast::before {
  background-color: var(--cast-underline);
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  width: 100%;
}
.credits > span.cast:not(:last-child)::before {
  width: 95%;
}
.credits > span.cast:not(:last-child)::after {
  content: ", ";
}
.credits > span.cast:hover {
  opacity: 1;
}
.credits > span.cast:hover::before {
  height: 3px;
}
.credits > span.nocast {
  opacity: 0.8;
}
