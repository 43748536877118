.mainLayout {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  transition: background-color var(--short-duration) ease-in;
}
.mainLayout.theaterMode {
  background-color: var(--player-background);
  justify-content: center;
}
.mainLayout.theaterMode > .circleLoader {
  position: absolute;
}
.mainLayout.theaterMode > .circleLoader > svg > circle {
  stroke-width: 10px;
}
.mainLayout.theaterMode > .circleLoader > svg > circle:last-child {
  stroke: var(--player-overlay-background);
}
.mainLayout > .workingZone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  opacity: 1;
  position: relative;
  transition: opacity var(--medium-duration) ease-in, visibility var(--medium-duration) ease-in;
  visibility: visible;
  z-index: 220;
}
.mainLayout > .workingZone.theaterMode {
  opacity: 0;
  visibility: hidden;
}
.mainLayout > .workingZone > .text {
  font-size: 70px;
}
