@import 'debug.css';
@keyframes pictoGrow {
  0% {
    transform: scale3d(0, 0, 1);
  }
  80% {
    transform: scale3d(1.2, 1.2, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 1);
  }
  50% {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1);
  }
  80% {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0, 0, 1);
  }
}
.playerView {
  --status-picto-size-u: 80px;
  --status-picto-margin-u: calc(var(--status-picto-size-u) / -2);
  align-items: center;
  background-color: var(--player-background);
  cursor: none;
  flex-direction: column;
  font: 14px var(--regular-font);
  inset: 0;
  justify-content: flex-start;
  position: absolute;
  z-index: 500;
}
.playerView > .videoContainer {
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */
  /*
  // For future use

  // Black (low opacity) / White
  &.whiteOnTranslucentBlack {
    p {
      background-color: var(--translucent-black-bg-subs);
    }
  }

  // Black / White
  &.whiteOnBlack {
    p {
      background-color: var(--black-bg-subs);
    }
  }

  // Transparent / Yellow
  &.yellowOnTransparent {
    span {
      color: var(--yellow-fg-subs) !important;
    }
  }

  // Black (low opacity) / Yellow
  &.yellowOnTranslucentBlack {
    p {
      background-color: var(--translucent-black-bg-subs);
    }

    span {
      color: var(--yellow-fg-subs) !important;
    }
  }

  // Black / Yellow
  &.yellowOnBlack {
    p {
      background-color: var(--black-bg-subs);
    }

    span {
      color: var(--yellow-fg-subs) !important;
    }
  }

  // White / Black
  &.blackOnWhite {
    p {
      background-color: var(--white-bg-subs);
    }

    span {
      color: var(--black-fg-subs) !important;
    }
  }

  // White (low opacity) / Black
  &.blackOnTranslucentWhite {
    p {
      background-color: var(--translucent-white-bg-subs);
    }

    span {
      color: var(--black-fg-subs) !important;
    }
  }
  */
}
.playerView > .videoContainer > video {
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */
}
.playerView > .videoContainer > video.shifted::-webkit-media-text-track-container {
  transform: translateY(-160px);
  transition: transform var(--short-duration) ease-out;
}
.playerView > .videoContainer > video::cue(.white) {
  color: #fff;
}
.playerView > .videoContainer > video::cue(.lime) {
  color: #0f0;
}
.playerView > .videoContainer > video::cue(.cyan) {
  color: #0ff;
}
.playerView > .videoContainer > video::cue(.red) {
  color: #f00;
}
.playerView > .videoContainer > video::cue(.yellow) {
  color: #ff0;
}
.playerView > .videoContainer > video::cue(.magenta) {
  color: #f0f;
}
.playerView > .videoContainer > video::cue(.blue) {
  color: #00f;
}
.playerView > .videoContainer > video::cue(.black) {
  color: #000;
}
.playerView > .videoContainer > video::cue(.bg_white) {
  background-color: #fff;
}
.playerView > .videoContainer > video::cue(.bg_lime) {
  background-color: #0f0;
}
.playerView > .videoContainer > video::cue(.bg_cyan) {
  background-color: #0ff;
}
.playerView > .videoContainer > video::cue(.bg_red) {
  background-color: #f00;
}
.playerView > .videoContainer > video::cue(.bg_yellow) {
  background-color: #ff0;
}
.playerView > .videoContainer > video::cue(.bg_magenta) {
  background-color: #f0f;
}
.playerView > .videoContainer > video::cue(.bg_blue) {
  background-color: #00f;
}
.playerView > .videoContainer > video::cue(.bg_black) {
  background-color: #000;
}
.playerView > .videoContainer > .shaka-text-container {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 48px;
  inset: 0;
  justify-content: flex-end;
  line-height: 1.4;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform: translateY(0);
  transition: transform var(--short-duration) ease-out;
}
.playerView > .videoContainer > .shaka-text-container * {
  display: initial;
  overflow: visible;
  position: unset;
}
.playerView > .videoContainer > .blackOutWrapper {
  align-items: center;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 520;
}
.playerView > .videoContainer > .blackOutWrapper > img {
  margin-top: -5%;
  width: 320px;
}
.playerView > .videoContainer > .blackOutWrapper > div {
  color: var(--player-controller-foreground);
  font-size: 24px;
  text-align: center;
  white-space: pre-line;
}
.playerView > .videoContainer > .blackOutWrapper > div.blackOutTitle {
  font-family: var(--bold-font);
  font-size: 36px;
  margin-block: 60px 40px;
}
.playerView > .videoContainer > .loaderWrapper {
  align-items: center;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: var(--opacity-short);
  width: 100%;
  z-index: 510;
}
.playerView > .videoContainer > .loaderWrapper.visible {
  opacity: 1;
}
.playerView > .videoContainer > .loaderWrapper > img {
  filter: blur(8px);
  height: 300px;
  opacity: 0.3;
  user-select: none;
  width: 600px;
}
.playerView > .videoContainer > .videoController {
  bottom: 0;
  height: 160px;
  position: absolute;
  width: 100%;
}
.playerView > .videoContainer > .statusPictoBackground {
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--player-status-picto-background);
  border-radius: 50%;
  justify-content: center;
  margin-top: var(--status-picto-margin-u);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: scale3d(0, 0, 1);
  width: var(--status-picto-size-u);
}
.playerView > .videoContainer > .statusPictoBackground.play, .playerView > .videoContainer > .statusPictoBackground.pause {
  left: 50%;
  margin-left: var(--status-picto-margin-u);
}
.playerView > .videoContainer > .statusPictoBackground.skipBackward {
  left: 10%;
}
.playerView > .videoContainer > .statusPictoBackground.skipForward {
  right: 10%;
}
.playerView > .videoContainer > .statusPictoBackground.play, .playerView > .videoContainer > .statusPictoBackground.skipBackward, .playerView > .videoContainer > .statusPictoBackground.skipForward {
  animation: blink var(--medium-duration) ease-in-out forwards;
}
.playerView > .videoContainer > .statusPictoBackground.pause {
  animation-duration: var(--short-duration);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-name: fadeIn, pictoGrow;
}
.playerView > .videoContainer > .statusPictoBackground > .pictoElement {
  width: 24px;
}
.playerView > .videoContainer > .statusPictoBackground > .pictoElement > svg {
  fill: var(--player-status-picto-foreground);
  pointer-events: none;
}
.playerView.visibleCursor {
  cursor: default;
}
.playerView.visibleCursor > .videoContainer {
  /* stylelint-disable selector-class-pattern */
  /* stylelint-enable selector-class-pattern */
}
.playerView.visibleCursor > .videoContainer > .shaka-text-container {
  transform: translateY(-160px);
}
.playerView.visibleCursor > .videoContainer > video::-webkit-media-text-track-container {
  transform: translateY(-160px);
  transition: transform var(--short-duration) ease-out;
}
.playerView > .backBar {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  left: 0;
  margin-top: 75px;
  opacity: 0;
  overflow: visible;
  padding-left: 60px;
  pointer-events: none;
  position: absolute;
  transition: var(--opacity-short);
  z-index: 530;
}
.playerView > .backBar.visible {
  opacity: 1;
  pointer-events: auto;
}
.playerView > .backBar > .pictoElement > svg {
  fill: var(--player-status-picto-foreground);
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.3));
  width: 30px;
}
