.legalNotice > .content > .legalContent {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  font: 16px var(--regular-font);
  max-width: 500px;
  overflow-y: auto;
  padding: 20px var(--modal-inline-padding-u) 50px;
  text-align: left;
  white-space: pre-line;
}
.legalNotice.dark > .content > .legalContent {
  color: var(--overlay-foreground-1);
}
.legalNotice.light > .content > .legalContent {
  color: var(--overlay-background);
}
