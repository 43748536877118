@keyframes debugSlideIn {
  0% {
    opacity: 0;
    transform: translateX(-110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes debugSlideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-110%);
  }
}
.start {
  flex-grow: 1;
  position: relative;
}
.start > .mainLayout {
  align-items: center;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  opacity: 0;
  z-index: 200;
}
.start > .mainLayout.visible {
  animation: fadeIn var(--short-duration) ease-out forwards;
}
.start > .mainLayout.hidden {
  animation: fadeOut var(--short-duration) ease-in forwards;
}
.start > .mainLayout > .debugContainer {
  background-color: #2a2a2a;
  border-radius: 2px 10px 2px 2px;
  bottom: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  left: 0;
  min-width: 240px;
  opacity: 0;
  padding-bottom: 8px;
  position: absolute;
  transform: translateX(-110%);
}
.start > .mainLayout > .debugContainer.slideIn {
  animation: debugSlideIn var(--short-duration) ease-out forwards;
}
.start > .mainLayout > .debugContainer.slideOut {
  animation: debugSlideOut var(--short-duration) ease-in forwards;
}
.start > .mainLayout > .debugContainer > .version {
  align-items: center;
  border-bottom: 1px solid #e6e8ea;
  color: #fff;
  font: 16px var(--regular-font);
  margin-bottom: 10px;
  padding: 5px;
}
.start > .mainLayout > .debugContainer > .version > svg {
  fill: #fff;
}
.start > .mainLayout > .debugContainer > .line {
  align-items: center;
  color: #949494;
  margin-bottom: 2px;
  padding: 1px 5px;
}
.start > .mainLayout > .debugContainer > .line > .status {
  background-color: #949494;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.start > .mainLayout > .debugContainer > .line > .status > .queryLoader {
  border-width: 3px;
  height: 20px;
  width: 20px;
}
.start > .mainLayout > .debugContainer > .line.inProgress {
  color: var(--warning);
}
.start > .mainLayout > .debugContainer > .line.inProgress > .status {
  background-color: var(--warning);
}
.start > .mainLayout > .debugContainer > .line.success {
  color: var(--success);
}
.start > .mainLayout > .debugContainer > .line.success > .status {
  background-color: var(--success);
}
.start > .mainLayout > .debugContainer > .line.error {
  color: var(--error);
}
.start > .mainLayout > .debugContainer > .line.error > .status {
  background-color: var(--error);
}
.start > .mainLayout > .debugContainer > .line > .label,
.start > .mainLayout > .debugContainer > .line > .status {
  align-items: center;
  color: inherit;
  font: 14px var(--regular-font);
}
.start > .mainLayout > .debugContainer > .line > .duration {
  font: 12px monospace;
  margin-right: 5px;
}
.start > .mainLayout > .debugContainer > .line > .label {
  margin-right: auto;
}
.start > .mainLayout > .progressBar {
  background-color: var(--text-foreground-1);
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0 center;
  transition: transform var(--short-duration) linear;
  width: 100%;
}
.start > .mainLayout > .loaderAnimation {
  height: 100%;
  width: 100%;
}
.start > .footerVersion {
  bottom: 10px;
  color: var(--text-foreground-1);
  font: 16px var(--regular-font);
  position: absolute;
  right: 10px;
  z-index: 1000;
}
.start > .startLoader {
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  z-index: 500;
}
