.watchingStatus {
  --watching-status-size-u: 80px;
  aspect-ratio: 1;
  background-color: var(--watching-status-background);
  border-radius: 50%;
  height: var(--watching-status-size-u);
  position: relative;
  width: var(--watching-status-size-u);
}
.watchingStatus.clickable {
  cursor: pointer;
}
.watchingStatus > svg {
  height: 104px;
  margin: -12px 0 0 -12px;
  width: 104px;
}
.watchingStatus > svg > circle {
  fill: transparent;
  stroke: var(--watching-status-progress-foreground);
  stroke-dasharray: 252;
  stroke-dashoffset: 0;
  stroke-width: 4px;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset var(--long-duration) ease-out;
}
.watchingStatus > svg > circle.background {
  stroke: var(--watching-status-progress-background);
}
.watchingStatus > .pictoElement {
  left: 50%;
  margin: -14px 0 0 -14px;
  position: absolute;
  top: 50%;
}
